<template>
<html class="h-100">
<body class="bg-gray-900">

    <div class="mt-3">
      <div class="mx-auto max-w-5xl px-5">
        <div class="max-w-[18rem] md:max-w-3xl mx-auto text-center">
          <!-- <h2 class="text-lg leading-6 font-semibold text-gray-400 uppercase tracking-wider">
            가격
          </h2> -->
          <p class="mt-20 text-3xl font-extrabold text-white md:text-4xl">
            아파트시장 Navigator 땅짚고PRO
          </p>
        </div>
      </div>

        <div class="mx-auto max-w-5xl px-5 mt-4">
          <div class="max-w-md mx-auto md:max-w-5xl">
            <div class="rounded-lg bg-gray-100 px-6 py-8 md:p-10 md:flex md:items-center">
              <div class="flex-1">
                <div class="text-start">
                  <h2
                    class="inline-flex px-4 py-1 rounded-full text-2xl font-semibold tracking-wide uppercase bg-yellow-300 text-black-600">
                    부동산빅데이터땅짚고
                  </h2>
                </div>
                <div class="text-start mt-1 text-lg text-gray-600">
                  땅짚고PRO는 <span class="font-bold">chrome</span>에 최적화 되어있어요.
                <div class="mt-3 flex items-left justify-left space-x-3"><img
                    src="../assets/google_chrome.svg" alt="구글 크롬 확장 프로그램"
                    class="w-8 h-8"> <img src="../assets/ms_edge.svg"
                    alt="마이크로소프트 엣지 확장 프로그램" class="w-8 h-8"> <img
                    src="../assets/naver_whale.png" alt="네이버 웨일 확장 프로그램"
                    class="w-8 h-8"></div>
                </div>
              </div>
              <div class="mt-6 rounded-md shadow md:mt-0 md:ml-10 md:flex-shrink-0">
                <a  href="#" @click="SignUpInGoogle"
                  class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-gray-50 text-decoration-none">
                  회원등록 및 사용신청
                </a>
                <a v-if="user" href="#"
                  class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-dark hover:bg-gray-50 text-decoration-none">
                  땅짚고PRO 로그인
                </a>
                <a v-else href="#" @click="handleSignInGoogle"
                  class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-danger hover:bg-gray-50 text-decoration-none">
                  땅짚고PRO 로그인
                </a>
              </div>
            </div>
          </div>
        </div>

      <div class="mt-4 pb-12 md:mt-12 md:pb-16 bg-gray-900">
        <div class="relative">
          <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
          <div class="mx-auto max-w-5xl px-5">
            <div class="relative z-10">
              <div class="max-w-md mx-auto space-y-4 md:max-w-5xl md:grid md:grid-cols-2 md:gap-5 md:space-y-0">
                <div data-bs-toggle="modal" data-bs-target="#exampleModal_form" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white cursor-pointer">
                  <div class="px-6 py-8 md:p-10 md:pb-6">
                    <div class="text-start">
                      <h3 id="tier-standard"
                        class="inline-flex px-4 py-1 rounded-full font-semibold tracking-wide uppercase bg-red-100 text-red-600">
                        2개월</h3>
                    </div>
                    <div class="text-gray-900 flex items-baseline text-4xl md:text-6xl font-extrabold">
                      1,000원
                      <span class="ml-1 text-2xl font-medium text-gray-500">
                        /day
                      </span>
                    </div>
                    <p class="mt-2 md:mt-5 text-base md:text-lg text-gray-500 text-start">
                      하루 1,000원 아파트시장 흐름을 한 눈에
                    </p>
                  </div>
                  <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6">
                    <ul role="list">
                    <!-- <div class="text-gray-900 flex items-baseline text-4xl md:text-6xl font-extrabold">
                      \144,000
                      <span class="ml-1 text-2xl font-medium text-gray-500">
                        원
                      </span>
                    </div> -->
                      <li class="flex items-start text-2xl">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                            stroke="currentColor" aria-hidden="true" class="text-lg h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3  text-gray-700">2개월 / \60,000원</p>
                      </li>
                      <!-- <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">국내 메이저 거래소 실시간 가격</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">바이낸스와 국내 거래소 가격 차이 계산 (김프)
                        </p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">미니 차트</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">창 크기 조절</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">코인 초성 검색</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">원, 달러 환율 정보</p>
                      </li> -->
                    </ul> 
                    <!-- <a href="#" @click="SignUpInGoogle" aria-describedby="tier-standard"
                      class="inline-flex rounded-lg shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-gray-800 hover:bg-gray-900">땅짚고PRO 회원등록 및 사용신청 하기</a> -->
                    <!-- <button @click="SignUpInGoogle" type="button" class="btn btn-lg btn-primary position-relative">땅짚고PRO 회원등록 및 사용신청 하기</button>
                    <button @click="handleSignInGoogle" class="btn btn-lg btn-danger">땅짚고PRO 로그인</button> -->
                  </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#exampleModal_form" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white cursor-pointer">
                  <div class="px-6 py-8 md:p-10 md:pb-6">
                    <div class="text-start">
                      <h3 id="tier-standard"
                        class="inline-flex px-4 py-1 rounded-full font-semibold tracking-wide uppercase bg-red-100 text-red-600">
                        6개월</h3>
                    </div>
                    <div class="text-gray-900 flex items-baseline text-4xl md:text-6xl font-extrabold">
                      800원
                      <span class="ml-1 text-2xl font-medium text-gray-500">
                        /day
                      </span>
                    </div>
                    <p class="mt-2 md:mt-5 text-base md:text-lg text-gray-500 text-start">
                      20% 할인된 가격으로 땅짚고PRO를 즐기세요.
                    </p>
                  </div>
                  <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-300 space-y-6">
                    <ul role="list">
                      <!-- <div class="text-gray-900 flex items-baseline text-4xl md:text-6xl font-extrabold">
                        \144,000
                        <span class="ml-1 text-2xl font-medium text-gray-500">
                          원
                        </span>
                      </div> -->
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-2xl text-gray-700">6개월 <s class="text-danger">\180,000원</s></p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-2xl text-gray-700">20%OFF \144,000원</p>
                      </li>
                      <!-- <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">내 자산 포트폴리오 모니터링</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">자동 스탑로스</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">미체결 거래 전체 원클릭 취소</p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                          </svg></div>
                        <p class="ml-2 md:ml-3 text-base text-gray-700">더 다양한 매수/매도 범위 지정 (1~100%)</p>
                      </li> -->
                    </ul> 
                    <!-- <a href="#" aria-describedby="tier-standard" class="
                        inline-flex
                        rounded-lg
                        shadow
                        items-center
                        justify-center
                        px-5
                        py-3
                        border border-transparent
                        text-base
                        font-medium
                        text-white
                        bg-gray-800
                        hover:bg-gray-900
                      bg-gradient-to-r from-red-600 to-pink-600">~10월 31일까지 무료</a> -->
                    <!-- <button @click="SignUpInGoogle" type="button" class="btn btn-lg btn-primary position-relative">땅짚고PRO 회원등록 및 사용신청
                      하기</button>
                    <button @click="handleSignInGoogle" class="btn btn-lg btn-danger">땅짚고PRO 로그인</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    
    <div class="text-center cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">


      <!-- 강의광고 -->
      <div class="bg-gray-900 p-5 rounded">
        <div role="main" class="inner cover text-white">
          <h3 class="cover-headin fw-bold">아파트 주요지표+보조지표 끝왕판분석</h3>
          <p class="lead">본 과정은 <span class="badge bg-warning text-dark">Power BI</span> 전문 교육과정 입니다.</p>
          <p class="lead">
            <button onclick="window.open('https://m.blog.naver.com/rmawn0504/222936244708',name,'scrollbars,width='+750+',height='+900);" type="button" class="btn btn-lg btn-primary position-relative hh1">
              파워BI 매매타이밍 잡는법
              <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span class="visually-hidden">unread messages</span>
              </span>
            </button>
          </p>
        </div>     
      </div>

      <div class="bg-gray-900 p-5 rounded">
        <div role="main" class="inner cover text-white mt-5">
          <h3 class="cover-headin fw-bold mt-5">코딩으로 분석하는 아파트시계열</h3>
          <p class="lead">Coding으로 실행하는 아파트투자 분석의 신세계</p>
          <p class="lead">
            <button
              onclick="window.open('https://m.blog.naver.com/rmawn0504/222936244708',name,'scrollbars,width='+750+',height='+900);" class="btn btn-lg btn-success hh1">파워BI 매매타이밍 잡는법
            </button>
          </p>
        </div>      
      </div>

      <div class="bg-gray-900 p-5 rounded">
        <div role="main" class="inner cover text-white mt-5">
          <h3 class="cover-headin fw-bold mt-5">아파트시계열도 이젠 PMP로 업그레이드!!</h3>
          <p class="lead">PMP란? Python, Mysql, PowerBI의 조합으로 분석하는 것</p>
          <p class="lead">
            <button
              onclick="window.open('https://m.blog.naver.com/rmawn0504/222936244708',name,'scrollbars,width='+750+',height='+900);"
              class="btn btn-lg btn-danger hh1">파워BI 매매타이밍 잡는법
            </button>
          </p>
        </div>      
      </div>
      <!-- 강의광고 -->





      

    </div>


</body>
</html>
</template>

<script>
export default {
  // computed: {
  //   user() {
  //     // console.log(this.$store.state.user)
  //     return this.$store.state.user;
  //   },
  // },    
  // mounted() {
  //   //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
  //   if (this.user.pro_user != 1) {
  //     console.log(this.user.pro_user)
  //     this.showAlert_NoUser();
  //     this.$router.push({ path: '/' });
  //   }    
  // },  
}
</script>


<style scoped>
.hh1 {


  /*
  8px 만큼 그림자를 흐리게 하고,
  색상은 노란색으로 ...
  */
  box-shadow: 10px 10px 8px yellow;
}
/*
 * Globals
 */


/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

.cover-container {
  max-width: 42em;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

</style>
