import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBepjdWzOn4rsmruh5tIaXH6dTLU8CMcI8",
  authDomain: "ddangzipgo.firebaseapp.com",
  projectId: "ddangzipgo",
  storageBucket: "ddangzipgo.appspot.com",
  messagingSenderId: "787480574745",
  appId: "1:787480574745:web:22e76031cddb3581ec2388",
  measurementId: "G-QEK0LP53GK"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp