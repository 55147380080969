import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
// import AgentList from '../views/AgentList.vue'
// import AgentListId from '../views/AgentListId.vue'
import AgentListId3 from '../views/AgentListId-good.vue'
import FastSell from '../views/FastSell.vue'
// import AptGradeTable from '../views/AptGradeTable.vue'
// import AptGradeTable2 from '../views/AptGradeTable-ag.vue'
import AptGradeTable3 from '../views/AptGradeTable-good.vue'
import UpDown from '../views/UpDown.vue'
import ChartSeries from '../views/ChartSeries.vue'
import SeriesCombo from '../views/SeriesCombo.vue'
import SeriesCombo1 from '../views/SeriesCombo1.vue'

import SeriesCompare from '../views/SeriesCompare.vue'
import test from '../views/test.vue'
import UseForm from '../views/UseForm.vue'
import KrHeatmapCopy from '../views/KrHeatmapCopy.vue'
import RealNews from '../views/RealNews.vue'
import D3Chart from '../views/D3-chart.vue'
// import VueBarChartRace from '../views/VueBarChartRace.vue'
import KrHeatmap from '../views/KrHeatmap.vue'




// const KrHeatmap = () => {
//   return import("../views/KrHeatmap.vue")
// }
// const KrHeatmapM = () => {
//   return import("../views/KrHeatmapM.vue")
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '땅짚고PRO'
    }
  },
  {
    path: '/news',
    name: 'RealNews',
    component: RealNews,
    meta: {
      title: '땅짚고PRO-뉴스'
    }    
  },    
  // {
  //   path: '/agentlist',
  //   name: 'AgentList',
  //   component: AgentList,
  //   meta: {
  //     title: '땅짚고PRO'
  //   }    
  // },  
  // {
  //   path: '/agentlist/:sido/:sigun',
  //   name: 'AgentListId',
  //   component: AgentListId,
  //   meta: {
  //     title: '땅짚고PRO-114'
  //   }    
  // },
  {
    path: '/agentlist-good/:sido/:sigun',
    name: 'AgentListId3',
    component: AgentListId3,
    meta: {
      title: '땅짚고PRO-114'
    }    
  },  
  // {
  //   path: '/agt',
  //   name: 'AptGradeTable',
  //   component: AptGradeTable,
  //   // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
  //   meta: {
  //     title: '땅짚고PRO-급지표'
  //   }      
  // },   
  {
    path: '/agt-good',
    name: 'AptGradeTable3',
    component: AptGradeTable3,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-급지표'
    }      
  }, 
  {
    path: '/updown',
    name: 'UpDown',
    component: UpDown,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-주간등락단지'
    }      
  },
  {
    path: '/chartseries',
    name: 'ChartSeries',
    component: ChartSeries,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-시계열차트'
    }      
  },
  {
    path: '/seriescombo',
    name: 'SeriesCombo',
    component: SeriesCombo,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-콤보차트'
    }      
  },  
  {
    path: '/seriescombo1',
    name: 'SeriesCombo1',
    component: SeriesCombo1,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-콤보차트1'
    }      
  },    
  {
    path: '/seriescompare',
    name: 'SeriesCompare',
    component: SeriesCompare,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO-시계열비교'
    }      
  },  
  {
    path: '/test',
    name: 'test',
    component: test,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: 'test'
    }      
  },  
  {
    path: '/useform',
    name: 'useform',
    component: UseForm,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: '땅짚고PRO사용신청'
    }      
  },     
  {
    path: '/d3chart',
    name: 'd3chart',
    component: D3Chart,
    // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
    meta: {
      title: 'D3Chart'
    }      
  },    
  // {
  //   path: '/VueBarChartRace',
  //   name: 'VueBarChartRace',
  //   component: VueBarChartRace,
  //   // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
  //   meta: {
  //     title: 'VueBarChartRace'
  //   }      
  // },                
  // {
  //   path: '/agt2/:sido/:sigun',
  //   name: 'AptGradeTable2',
  //   component: AptGradeTable2,
  //   // component: () => import(/* webpackChunkName: "agt" */ '../views/AptGradeTable.vue'),
  //   meta: {
  //     title: '땅짚고PRO-급지표'
  //   }      
  // },    
  {
    path: '/fastsell/:sido/:sigun',
    name: 'FastSell',
    component: FastSell,
    meta: {
      title: '땅짚고PRO-급매'
    }    
  },    
  {
    path: '/krheatmap',
    name: 'KrHeatmap',
    component: KrHeatmap,
    meta: {
      title: '땅짚고PRO-증감지도'
    }    
  },  
  // {
  //   path: '/krheatmapm',
  //   name: 'KrHeatmapm',
  //   component: KrHeatmapM,
  //   meta: {
  //     title: '땅짚고PRO-월간증감지도'
  //   }    
  // },    
  {
    path: '/krheatmapcopy',
    name: 'KrHeatmapCopy',
    component: KrHeatmapCopy,
    meta: {
      title: '땅짚고PRO-증감지도'
    }    
  },       
  {
    path: '/about',
    name: 'About',
    component: Home,
    meta: {
      title: '땅짚고PRO'
    }    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

//페이지 타이틀 설정
router.beforeEach((to, from, next) => {
 /*title */
 if (to.meta.title) {
  document.title = `${to.meta.title}`;
 }
 next()
})
