//firebase사용하기
import firebaseConfig from './firebaseConfig';
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";
const provider = new GoogleAuthProvider();
const auth = getAuth();
firebaseConfig
//firebase사용하기

import axios from 'axios';

import jwt_decode from "jwt-decode";

export default {
    computed: {
      user() {
          return this.$store.state.user;
      },
    },    
    mounted() {
      //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
      // if (this.pro_user != 1) {
      //   this.showAlert_NoUser();
      //   this.$router.push({ path: '/' });
      // }
    },
    created() {
        if (!this.$cookies.get("account")) {
            // this.showAlert_logout();
            this.Logout();
        }      
    },    
    methods: {
      leave(event) {
        event.preventDefault();
        event.returnValue = '';
        this.Logout();
      },        
      async $api(url, method, data) {
          return (await axios({
              method: method,
              url,
              data
          }).catch(e => {
              console.log(e);
          })).data;
      },
      async login_yn(이메일) {
        let 로그인여부 = await this.$api("/api/login_yn",'post', { param: 이메일 });
        this.UserAcount = 로그인여부
        // this.pro_user = this.UserAcount.pro_user
        // this.$store.commit("user", UserAcount[0])
      },
      //카카오로그인시스템
      // kakaoLogin() {
      //   window.Kakao.Auth.login({
      //     scope: 'profile_nickname	, account_email, gender, age_range',
      //     success: this.getProfile
      //   });
      // },
      // async login(kakao_account) {
      //   await this.$api('/api/login', {
      //     param: [
      //       { email: kakao_account.email, nickname: kakao_account.profile.nickname, gender: kakao_account.gender, age_range: kakao_account.age_range },
      //       { nickname: kakao_account.profile.nickname, gender: kakao_account.gender, age_range: kakao_account.age_range }
      //       ]
      //   });
      //   this.$store.commit("user", kakao_account)
      // },
      // kakaoLogout() {
      //   window.Kakao.Auth.logout((response) => {
      //     console.log(response);
      //     this.$store.commit("user", {});
      //     // alert("로그아웃 성공!");
      //     this.showAlert('로그아웃');
      //     this.$router.push({ path: '/' });
      //   });
      // },
      // getProfile(authObj) {
      //   console.log(authObj);
      //   window.Kakao.API.request({
      //     url: '/v2/user/me',
      //     success: res => {
      //       const kakao_account = res.kakao_account;
      //       console.log(kakao_account);
      //       this.login(kakao_account);
      //       // alert("로그인 성공!");
      //       // this.showAlert(user.profile.nickname);
      //       this.showAlert('로그인');
      //     }
      //   });
      // },    
      //firebase-회원등록
      SignUpInGoogle() {
          signInWithPopup(auth, provider)
              .then((result) => {
              const google_account = result.user;
              //파이어베이스구글로그인되면해당땅짚고에도등록시키기!!
              this.signup(google_account);
              //회원등록후이용신청유도
              this.showAlert_signup_complete();
          }).catch((error) => {
              console.log(error);
          });
      },
      //땅짚고로그인
      handleSignInGoogle() {
          signInWithPopup(auth, provider)
              .then((result) => {
              const google_account = result.user;
              this.google_account = google_account;
              this.Login(result.user.email);
              this.email = result.user.email;
              this.isSignedIn = true;
          }).catch((error) => {
              console.log(error);
          });
      },
      handlesignOut() {
          signOut(auth).then(() => {
              this.pro_user = 2;
              this.isSignedIn = false;
              // this.$store.commit("user", {});
              this.showAlert_logout();
              this.Logout();
              // this.$router.push({ path: "/" });
          }).catch((error) => {
              console.log(error);
          });
      },
      //땅짚고등록
      async signup(google_account) {
          await axios.post("/signup", {
              param: [
                  { email: google_account.email, nickname: google_account.displayName, createdAt: new Date(parseInt(google_account.metadata.createdAt) + 9 * 60 * 60 * 1000), lastLoginAt: new Date(parseInt(google_account.metadata.lastLoginAt) + 9 * 60 * 60 * 1000) },
                  { nickname: google_account.displayName, createdAt: new Date(parseInt(google_account.metadata.createdAt) + 9 * 60 * 60 * 1000), lastLoginAt: new Date(parseInt(google_account.metadata.lastLoginAt) + 9 * 60 * 60 * 1000) }
              ]
          });
          let google_account1 = new Object();
          google_account1["pro_user"] = this.pro_user;
          google_account1["email"] = google_account.email;
          google_account1["nickname"] = google_account.displayName;
          google_account1["accessToken"] = google_account.accessToken;
          google_account1["metadata"] = google_account.metadata;
          // google_account1['pro_user']=1000000000;
          // google_account1['pro_user']=1000000000;
          // google_account1=google_account
          console.log(google_account);
          // this.$store.commit("user", google_account1)
      },
      //로그인+쿠키
      // async Login(email) {
      //     await axios
      //         .post("/login/account", { loginEmail: email })
      //         .then((res) => {
      //         this.pro_user = res.data.pro_user;
      //         this.token = res.data.token;
      //         this.$store.commit("user", res.data);
      //         if (res.data.pro_user == 1) {
      //           if (res.data.login_yn == 'logout') {
      //             this.showAlert_login();
      //           } else if (res.data.login_yn == 'login') {
      //             this.showAlert_MultiLogin();
      //             axios.delete("/login/account").then(() => {
      //                 this.token = "";
      //                 this.$store.commit("user", {});
      //                 // alert("로그아웃하였습니다.");
      //             });                          
      //           }
      //         }
      //         else if (res.data.pro_user == 0) {
      //           this.showAlert_NoUser();
      //           this.Logout();
      //         }
      //         // alert("로그인에 성공했습니다."+this.pro_user+this.token);
      //         console.log("로그인???", res.data);
      //     })
      //         .catch(() => {
      //         alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
      //     });
      // },
      async Login(email) {
          await axios
              .post("/login/account", { loginEmail: email })
              .then((res) => {
              console.log('클라이언트res:',res)
              if (res.data=="중복로그인") {
                //중복로그인 일 때
                this.showAlert_MultiLogin();
                this.Logout();

              } else {
                //중복로그인이 아닐 때
                this.pro_user = res.data.pro_user;
                // this.token = res.data.token;
                if (res.data.pro_user == 1) {
                    this.$store.commit("user", document.cookie)
                    
                    this.showAlert_login();
                    // alert("로그인에 성공했습니다." + this.pro_user + this.$cookies.get("keyin"));
                }
                else {
                    this.showAlert_NoUser();
                    // alert("사용권한이 없습니다!!!!!!")
                    this.Logout();
                }                  
              }

          })
              .catch(() => {
              //회원등록이 안되어 있을 때 에러가 난다. 따라서 회원등록 권유!!
              this.showAlert_SignUp();
              // alert("회원등록후 이용해 주시기 바랍니다.");
          });
      },
      async Logout() {
          await axios.post("/logout/account", {});
          this.$store.commit("user", null);
          this.$router.push({ path: "/" });

          // await this.$api("/api/login", "post", {
          //     param: [
          //         { email: this.user.email, login_yn:'logout' },
          //         { email: this.user.email, login_yn:'logout' }
          //     ]
          // });              
          // await axios.delete("/login/account").then(() => {
          //     this.token = "";
          //     this.$store.commit("user", {});
          //     // alert("로그아웃하였습니다.");
          // });        
      },
      //로그인+쿠키              
      decode(xxx) {
          if (xxx) {
              let aaa = jwt_decode(xxx);
              return aaa;
          }
          else {
              return { pro_user: 2 };
          }
      },
      Getnickname() {
        if(!this.$cookies.get("account")) {
            return 'undefined'
        } else {
            return this.$cookies.get("account").nickname
        }
      },
      showAlert_login() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "로그인 되었습니다.",
              showConfirmButton: false,
              timer: 1500
          });
      },
      showAlert_logout() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "로그아웃 되었습니다.",
              showConfirmButton: false,
              timer: 1500
          });
      },
      showAlert_SignUp() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "회원등록 후 이용해 주세요!!",
              // text: ' 본 땅짚고PRO 사용을 위해서 아래 회원등록을 해주세요.',                
              showConfirmButton: true,
              // confirmButtonColor: '#3085d6',                
              // showCancelButton: true,
              // confirmButtonText: '<button class="btn btn-primary btn-sm ms-2" @click="SignUpInGoogle"><i class="bi bi-google"></i>회원등록하기</button>',
              // showDenyButton: true,
              // denyButtonText: `확인`,
              // footer: '<button class="btn btn-primary btn-sm ms-2" onclick="SignUpInGoogle()"><i class="bi bi-google"></i>회원등록하기</button>'             
              // timer: 1500
          });
      },
      showAlert_NoUser() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "이용신청 후 이용해 주세요!",
              text: "만약 이용신청 및 입금완료 하신분은 010-9664-2399로 연락주시면 빠르게 처리해 드리겠습니다. ",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // showCancelButton: true,
              confirmButtonText: "<span data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal_form\" ><i class=\"bi bi-ui-checks-grid\"></i> 이용신청</span>",
              showDenyButton: true,
              denyButtonText: `확인`,
          });
      },
      showAlert_signup_complete() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "회원가입이 완료되었습니다.",
              text: "아래 이용신청 후 사용해주시기 바랍니다.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // showCancelButton: true,
              confirmButtonText: "<span data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal_form\" ><i class=\"bi bi-ui-checks-grid\"></i> 이용신청</span>",
              showDenyButton: true,
              denyButtonText: `확인`,
              // timer: 1500
          });
      },
      showAlert_MultiLogin() {
          this.$swal.fire({
              // position: 'bottom-end',
              icon: "success",
              title: "중복로그인 감지되었습니다!!",
              showConfirmButton: false,
              timer: 1500
          });
      },
      async getNewsDateList() {
          let NewsDateList = await this.$api("/api/NewsDateList", {});
          this.NewsDateList = NewsDateList;
          let DateList = [];
          for (var i in NewsDateList) {
              DateList.push(NewsDateList[i].ymd);
          }
          console.log(DateList[DateList.length - 1]);
          this.ymd = DateList[DateList.length - 1];
          this.DateList = DateList.sort().reverse();
      },
    //   getnickname() {
    //     return this.$cookies.get(this.$cookies.keys()[0]).nickname
    //   }         
    }
}