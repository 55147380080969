<template>
  <div class="example">
    <h4>Options API Line chart</h4>
    <apexchart width="500" height="350" type="line" :options="chartOptions" :series="series"></apexchart>
    <div>
      <button @click="updateChart">Update!</button>
      <button @click="addSeries">Push series!</button>
      <button @click="modifySeries">Update first value all series!</button>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
// import the component
import Treeselect from 'vue3-treeselect'
// import the styles
import 'vue3-treeselect/dist/vue3-treeselect.css'

/* eslint-disable */
export default {
  components: {
    Treeselect,
    apexchart: VueApexCharts,  
  },    
  name: "LineExample",
  computed: {
    user() {
        return this.$store.state.user;
    },
  },  
  mounted() {
    //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
    if (this.user != 1) {
        this.showAlert_NoUser();
        this.$router.push({ path: '/' });
    }         
  },  
  data: function() {
    return {
      chartOptions: {
        xaxis: {
          type: "datetime",
          categories: [
            "01/01/2003",
            "02/01/2003",
            "03/01/2003",
            "04/01/2003",
            "05/01/2003",
            "06/01/2003",
            "07/01/2003",
            "08/01/2003"
          ]
        }
      },
      series: [
        {
          name: "Series A",
          data: this.generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
            min: 10,
            max: 20
          })
        },
        {
          name: "Series B",
          data: this.generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
            min: 10,
            max: 20
          })
        }
      ]
    };
  },
  methods: {
    generateDayWiseTimeSeries(baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push([x, y]);
        baseval += 86400000 * 7;
        i++;
      }
      return series;
    },
    updateChart() {
      console.debug("Update chart");
      let series = [
        {
          name: "South",
          data: this.generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
            min: 10,
            max: 60
          })
        },
        {
          name: "North",
          data: this.generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
            min: 10,
            max: 50
          })
        },
        {
          name: "Central",
          data: this.generateDayWiseTimeSeries(new Date("11 Feb 2017").getTime(), 20, {
            min: 10,
            max: 50
          })
        }
      ];
      this.series = series;
    },
    addSeries() {
      console.debug('Push new series');
      this.series.push({
        name: 'pushedDataSeries',
        data: this.generateDayWiseTimeSeries(new Date('11 Feb 2017').getTime(), 20, {
          min: 0,
          max: 100,
        }),
      });
    },
    modifySeries() {
      console.debug('Modify series');
      this.series.forEach((serie) => {
        // eslint-disable-next-line no-param-reassign
        serie.data[0][1] = Math.floor(Math.random() * (40 - 0 + 1)) + 0;
      });
    },
    showAlert() {
      this.$swal.fire({
          icon: 'warning',
          title: '로그인이 필요합니다.',
      });
    },
    showAlert_login() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그인 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },
    showAlert_logout() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그아웃 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },    
    showAlert_NoUser() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '사용권한이 없습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },
          
  }
};
</script>