<template>

<div id="app">
    
    <div v-if="$isMobile()">
        <main class="mt-1">
        
        <div class="container-fluid">

            <div>
                <div class="input-group input-group-sm mb-1">
                    <span class="input-group-text">지역선택</span>
                    <div>
                        <select class="form-select form-select-sm" v-model="sido" @change="changeSido(sido);">
                            <option :value="a.cortarName" :key=i v-for="(a,i) in SidoList">
                                {{a.cortarName}}
                            </option>
                        </select>
                    </div>
                    <!-- <span class="input-group-text">시군구</span> -->
                    <div>
                        <select class="form-select form-select-sm" v-model="sigun" @change="changeSigun(sigun);">
                            <option :value="a['sigun']" :key=i v-for="(a, i) in SigunList1">
                                {{a.sigun }}
                            </option>
                        </select>
                    </div>
                
                    <span class="input-group-text">싼매물 <span class="badge bg-danger">{{sortedData.length }}</span></span>
                </div>
                
                <div class="btn-toolbar mb-1" role="toolbar" aria-label="Toolbar with button groups" title="급매정렬하기">
                
                    <div class="btn-group col-12 me-2" role="group" aria-label="First group">
                        <!-- <div class="btn btn-secondary btn-sm me-1">급매정렬</div> -->
                        <!-- <div class="btn btn-primary btn-sm me-1" id="btnSort" @click="doSort('atclNm')">단지명<span
                                                            v-if="sort.field=='atclNm'"><span v-if="sort.desc"><i class="bi bi-sort-down"></i></span><span v-else><i
                                                                    class="bi bi-sort-up-alt"></i></span></span></div> -->
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('gap_rate')">급매비율
                            <span v-if="sort.field=='gap_rate'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i class="bi float-end bi-justify"></i></span>
                        </div>
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('gap_hoga')">호가갭
                            <span v-if="sort.field=='gap_hoga'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('gap')">매전갭
                            <span v-if="sort.field =='gap'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('atclCfmYmd')">확인일자
                            <span v-if="sort.field=='atclCfmYmd'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                    </div>
                    <div class="btn-group col-12 me-2 mt-1" role="group" aria-label="First group">
                
                
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('spc2')">전용면적
                            <span v-if="sort.field=='spc2'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('totalHouseholdCount')">대단지
                            <span v-if="sort.field =='totalHouseholdCount'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('useApproveYmd')">년식
                            <span v-if="sort.field=='useApproveYmd'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                
                        <div class="btn col-3 btn-primary btn-sm me-1" id="btnSort" @click="doSort('prc')">급매가격
                            <span v-if="sort.field =='prc'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                    v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                    class="bi float-end bi-justify"></i></span>
                        </div>
                
                    </div>
                </div>
            </div>

        
            <div class="row mb-2">
   
                <!-- <div class="col-sm-12 mb-1">
                        <input type="text" id="myFilter" class="form-control" @keyup="myFunction()" placeholder="정렬후 결과값 필터링(동,단지명 등으로 검색해 보세요)">
                    </div> -->
    
    
                <div id="my_card" class="card-deck scroll">
                    <div class="card mb-3 text-dark bg-light" v-for="(item, i) in sortedData.slice(0,100)"
                        :value="item['id']" :key=i>
                        <div class="row g-0" @click="goPage(item.atclNo,'매물상세보기',650,800)" style="cursor:pointer;">
                            <div class="col-md-11">
                                <div style="font-size:0.8em" class="card-body">
                                    <div style="font-size:0.8em" class="cortarAddress">
                                        <h6 class="card-title text-start fw-bold">{{item.cortarAddress}}</h6>
                                    </div>
                                    <div class="card-text text-start fs-6">
                                        <div class="atclNm">
                                            <h6 class="card-title text-start fw-bold">{{item.atclNm}}</h6>
                                        </div>
                                        <div style="font-size:0.8em" class="hanPrc"><b style="color:#4c94e8;font-size: larger;"
                                                title="호가정보">{{item.tradTpNm}} {{item.hanPrc}}&nbsp;</b>
                                            <span class="prc_info" title="시세정보">
                                                <span class="btn btn-outline-primary btn-sm atclCfmYmd"
                                                    style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .50rem; --bs-btn-border-radius: .0rem;">
                                                    <b>매매시세</b>
                                                </span>{{item.dealAveragePrice.toLocaleString('ko-KR')}} <span
                                                    class="btn btn-outline-danger btn-sm atclCfmYmd"
                                                    style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .50rem; --bs-btn-border-radius: .0rem;">
                                                    <b>전세시세</b>
                                                </span>{{item.leaseAveragePrice.toLocaleString('ko-KR')}}
                                            </span>
                                        </div>
                                        <div class="prc"><span v-if="Number(item.gap_rate)<0.8"
                                                class="badge text-bg-danger">20%↓ 급매</span> <span
                                                v-if="Number(item.gap_rate)<1" class="badge text-bg-success">시세 대비
                                                {{(100-item.gap_rate*100).toFixed(2)}}% 저렴</span></div>
                                        <div style="font-size:0.8em" class="flrInfo">{{item.rletTpNm}}, {{item.spc1}}㎡/{{item.spc2}}㎡,
                                            {{item.flrInfo}}, {{item.direction}},
                                            {{item.totalHouseholdCount.toLocaleString('ko-KR')}}세대,
                                            {{use_year(item.useApproveYmd)}}년차</div>
                                        <div style="font-size:0.8em" class="atclFetrDesc">{{item.atclFetrDesc}}</div>
                                        <div style="font-size:0.8em" class="gap_rate2">시세 대비 호가 수준:{{(item.gap_rate*100).toFixed(2)}}%
                                            /
                                            <span title="매물가격-전세평균시세">
                                                <span v-if="Number(item.gap_hoga)<0" class="badge text-bg-warning">플피</span>
                                                호가갭:{{item.gap_hoga.toLocaleString('ko-KR')}}
                                            </span>
                                            /
                                            <span title="매매평균시세-전세평균시세">
                                                <span v-if="Number(item.gap)<0"
                                                    class="badge text-bg-warning">플피</span>매전갭:{{item.gap.toLocaleString('ko-KR')}}
                                            </span>
    
    
                                        </div>
                                        <div class="btn btn-outline-danger btn-sm atclCfmYmd"
                                            style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .75rem; --bs-btn-border-radius: .0rem;">
                                            <b>확인 {{item.atclCfmYmd}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        
        </div>
        
        </main>
    </div>

    <div v-else>
        <main class="mt-1">

            <!-- <div>시세 대비 저렴한 매물 개수: {{ sortedData.length }}</div>
                            <button type="button" class="btn btn-primary" style="width:500px;">
                                시세 대비 저렴한 매물 개수 <span class="badge bg-danger">{{ sortedData.length }}</span>
                            </button> -->

            <div v-if="isLoading" class="loading-container">
                <div class="loading">
                    <Fade-loader />
                </div>
            </div>

        
            <div class="container-fluid">
        
                <!-- <div class="alert alert-primary" role="alert">
                    시세 대비 저렴한 매물 개수 <span class="badge bg-danger">{{ sortedData.length }}</span>
                </div> -->
        
                <div class="row mb-2">
                    <div class="col-12">
                        <div class="row">
        
                            <div class="input-group mb-1">
                                <span class="input-group-text">시도</span>
                                <div class="col-auto">
                                    <select class="form-select" v-model="sido" @change="changeSido(sido);">
                                        <option :value="a.cortarName" :key=i v-for="(a,i) in SidoList">
                                            {{a.cortarName}}
                                        </option>
                                    </select>
                                </div>
                                <span class="input-group-text">시군구</span>
                                <div class="col-auto">
                                    <select class="form-select" v-model="sigun" @change="changeSigun(sigun);">
                                        <option :value="a['sigun']" :key=i v-for="(a, i) in SigunList1">
                                            {{a.sigun }}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div class="col-auto">
                                                                <select class="form-select" v-model="si3">
                                                                    <option :value="a" :key=i v-for="(a,i) in Sigun3">{{a}}</option>
                                                                </select>
                                                            </div> -->
                                <span class="input-group-text">시세 대비 저렴한 매물 개수 <span class="badge bg-danger">{{
                                sortedData.length }}</span></span>
                            </div>
        
        
                        </div>
                    </div>
        
        
        
                    <div class="btn-toolbar mb-1" role="toolbar" aria-label="Toolbar with button groups" title="급매정렬하기">
        
                        <div class="btn-group col-12 me-2" role="group" aria-label="First group">
                            <button type="button" class="btn btn-secondary btn-sm me-1">급매정렬</button>
                            <!-- <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort" @click="doSort('atclNm')">단지명<span
                                        v-if="sort.field=='atclNm'"><span v-if="sort.desc"><i class="bi bi-sort-down"></i></span><span v-else><i
                                                class="bi bi-sort-up-alt"></i></span></span></button> -->
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort"
                                @click="doSort('gap_rate')">급매비율
                                <span v-if="sort.field=='gap_rate'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort"
                                @click="doSort('gap_hoga')">호가갭
                                <span v-if="sort.field=='gap_hoga'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort" @click="doSort('gap')">매전갭
                                <span v-if="sort.field=='gap'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort"
                                @click="doSort('atclCfmYmd')">확인일자
                                <span v-if="sort.field =='atclCfmYmd'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort" @click="doSort('spc2')">전용면적
                                <span v-if="sort.field =='spc2'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
        
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort"
                                @click="doSort('totalHouseholdCount')">대단지
                                <span v-if="sort.field =='totalHouseholdCount'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort"
                                @click="doSort('useApproveYmd')">년식
                                <span v-if="sort.field=='useApproveYmd'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
        
                            <button type="button" class="btn btn-primary btn-sm me-1" id="btnSort" @click="doSort('prc')">급매가격
                                <span v-if="sort.field=='prc'"><span v-if="sort.desc"><i class="bi float-end bi-sort-down"></i></span><span
                                        v-else><i class="bi float-end bi-sort-up-alt"></i></span></span><span v-else><i
                                        class="bi float-end bi-justify"></i></span>
                            </button>
        
                        </div>
                    </div>
        
        
        
        
                    <!-- <div class="col-sm-12 mb-1">
                            <input type="text" id="myFilter" class="form-control" @keyup="myFunction()" placeholder="정렬후 결과값 필터링(동,단지명 등으로 검색해 보세요)">
                        </div> -->
        
        
                    <div id="my_card" class="card-deck scroll">
                        <div class="card mb-3 text-dark bg-light" v-for="(item, i) in sortedData.slice(0,100)"
                            :value="item['id']" :key=i>
                            <div class="row g-0" @click="goPage(item.atclNo,'매물상세보기',650,800)" style="cursor:pointer;">
                                <div class="col-md-11">
                                    <div class="card-body">
                                        <div class="cortarAddress">
                                            <h6 class="card-title text-start fw-bold">{{item.cortarAddress}}</h6>
                                        </div>
                                        <div class="card-text text-start fs-6">
                                            <div class="atclNm">
                                                <h5 class="card-title text-start fw-bold">{{item.atclNm}}</h5>
                                            </div>
                                            <div class="hanPrc"><b style="color:#4c94e8;font-size: larger;"
                                                    title="호가정보">{{item.tradTpNm}} {{item.hanPrc}}&nbsp;</b>
                                                <span class="prc_info" title="시세정보">
                                                    <span class="btn btn-outline-primary btn-sm atclCfmYmd"
                                                        style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .50rem; --bs-btn-border-radius: .0rem;">
                                                        <b>매매시세</b>
                                                    </span>{{item.dealAveragePrice.toLocaleString('ko-KR')}} <span
                                                        class="btn btn-outline-danger btn-sm atclCfmYmd"
                                                        style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .50rem; --bs-btn-border-radius: .0rem;">
                                                        <b>전세시세</b>
                                                    </span>{{item.leaseAveragePrice.toLocaleString('ko-KR')}}
                                                </span>
                                            </div>
                                            <div class="prc"><span v-if="Number(item.gap_rate)<0.8"
                                                    class="badge text-bg-danger">20%↓ 급매</span> <span
                                                    v-if="Number(item.gap_rate)<1" class="badge text-bg-success">시세 대비
                                                    {{(100-item.gap_rate*100).toFixed(2)}}% 저렴</span></div>
                                            <div class="flrInfo">{{item.rletTpNm}}, {{item.spc1}}㎡/{{item.spc2}}㎡,
                                                {{item.flrInfo}}, {{item.direction}},
                                                {{item.totalHouseholdCount.toLocaleString('ko-KR')}}세대,
                                                {{use_year(item.useApproveYmd)}}년차</div>
                                            <div class="atclFetrDesc">{{item.atclFetrDesc}}</div>
                                            <div class="gap_rate2">시세 대비 호가 수준:{{(item.gap_rate*100).toFixed(2)}}%
                                                /
                                                <span title="매물가격-전세평균시세">
                                                    <span v-if="Number(item.gap_hoga)<0" class="badge text-bg-warning">플피</span>
                                                    호가갭:{{item.gap_hoga.toLocaleString('ko-KR')}}
                                                </span>
                                                /
                                                <span title="매매평균시세-전세평균시세">
                                                    <span v-if="Number(item.gap)<0"
                                                        class="badge text-bg-warning">플피</span>매전갭:{{item.gap.toLocaleString('ko-KR')}}
                                                </span>
        
        
                                            </div>
                                            <div class="btn btn-outline-danger btn-sm atclCfmYmd"
                                                style="--bs-btn-padding-y: .1rem; --bs-btn-padding-x: .2rem; --bs-btn-font-size: .75rem; --bs-btn-border-radius: .0rem;">
                                                <b>확인 {{item.atclCfmYmd}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </div>
        
            </div>
        
        </main>
    </div>

</div>



</template>

<script>
import $ from 'jquery/dist/jquery.slim';

//로딩바
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

export default {
	components: {
        FadeLoader,
		// CloudTables,
	},
    computed: {
        // user() {
        //     return this.$store.state.user;
        // },
        sortedData() {

            if(!this.sort.field){
                return this.FastSellList
            }

            return this.FastSellList.concat().sort((a,b)=>{
                if(this.sort.desc){
                    return a[this.sort.field] > b[this.sort.field] ? -1:1        
                }else{
                    return a[this.sort.field] > b[this.sort.field] ? 1:-1                  
                }
            })
        }        
    },
    mounted() {


        // //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
        // if (this.user.pro_user != 1) {
        //     this.showAlert_NoUser();
        //     this.$router.push({ path: '/' });
        // }         


        $(document).ready(function(){
            var height_value = $( window ).height()-150;
            $(".scroll").css("height", height_value); 
        });
        // 버튼클릭시 스크롤 맨위로
        $(".btn").on("click", function() {
            $('#my_card').scrollTop(0);
            $('#myFilter').val('')
        });        
        // $("#myFilter").on("keydown",function(key){
        //     if(key.keyCode==13) { 
        //         // key.preventDefault();
        //         $('#myFilter').val('');
        //         alert('aff');
        //     }
        // });        

    },
    created() {
        this.sido = "경상남도"
        this.sido = this.$route.params.sido;
        this.sigun = this.$route.params.sigun;
        
        this.getFastSellList();
        this.getSidoList();
        this.getSigunList();
        this.getSigunList1();
        this.getDongList();
        this.getRowId = (params) => params.data.id;
    },
    data() {
        return {
            isLoading: false,
            FastSellList: [],
            SidoList: [],
            SigunList: [],
            SigunList1: [],
            DongList: [],
            Sigun1: [],
            Sigun2: [],
            Sigun3: [],
            sido: "경상남도",
            sigun: "진주시",
            dong: "",
            columnDefs:[],
            rowData:[],
            rowSelection: null,
            getRowId:'',
            height_value:'',
            sort: {
                field: 'gap_rate',
                desc: false        
            },
        };
    },
    methods: {
        async getFastSellList() {
            this.isLoading = true
            this.FastSellList = await this.$api("/api/FastSellList",'post', { param: [this.sido,this.sigun] });
            this.isLoading = false

        },


        async getSidoList() {
            let SidoList = await this.$api("/api/SidoList",'post', {});
            this.SidoList = SidoList;

        },
        async getSigunList() {
            let SigunList = await this.$api("/api/SigunList",'post', {});

            this.SigunList = SigunList;
        },
        async getSigunList1() {
            let SigunList1 = await this.$api("/api/SigunList1",'post', { param: [this.sido] });

            this.SigunList1 = SigunList1;
        },
        async getDongList() {
            let DongList = await this.$api("/api/DongList",'post', {});
            this.DongList = DongList;
        },
        changeSido(a) {
            this.$router.push({ path: '/fastsell/' + a + '/' + this.sigun });
        },
        changeSigun(a) {
            // console.log(a)
            this.$router.push({ path: '/fastsell/' + this.sido + '/' + a });
        },
        changeDong() {

        },
        goPage(atclNo,name,w,h) {
          window.open('https://m.land.naver.com/article/info/'+atclNo+'?newMobile',name,'scrollbars,width='+w+',height='+h);
        },
        goPage1(atclNo) {
          alert(atclNo);
        },        
        getSelectedRowData() {
        const selectedData = this.gridApi.getSelectedRows();
        return selectedData;
        },
        rowWasClicked: event => {
            // alert(event.data.atclNo);
            // alert($( window ).height())
            window.open('https://m.land.naver.com/article/info/'+event.data.atclNo+'?newMobile','매물상세페이지','scrollbars,width='+650+',height='+800);
        },
        // showAlert() {
        //     this.$swal.fire({
        //         // position: 'bottom-end',
        //         icon: 'warning',
        //         title: '로그인이 필요합니다.',
        //         // showConfirmButton: false,
        //         // timer: 1500
        //     });
        //     // this.$Swal({
        //     //   position: 'top-end',
        //     //   icon: 'success',
        //     //   title: 'Your work has been saved',
        //     //   showConfirmButton: false,
        //     //   timer: 1500
        //     // });      
        // },
        doSort(field) {
            if(field == this.sort.field){
                this.sort.desc = !this.sort.desc
            }else{
                this.sort.field = field;
                this.sort.desc = true;
            }
        },
        // 필터링
        myFunction() {
            var input, filter, cards, cardContainer, title, i;
            input = document.getElementById("myFilter");
            filter = input.value.toUpperCase();
            cardContainer = document.getElementById("my_card");
            cards = cardContainer.getElementsByClassName("card");
            for (i = 0; i < cards.length; i++) {
                title = cards[i].querySelector(".card-body");
                if (title.innerText.toUpperCase().indexOf(filter) > -1) {
                    cards[i].style.display = "";
                } else {
                    cards[i].style.display = "none";
                }
            }
        },
        use_year(use_date) {
            let today = new Date();
            let useApprove = new Date(use_date);

            let diff = today.getFullYear() - useApprove.getFullYear();
            // console.log(params)
            return diff;
        },


        // showAlert_login() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '로그인 되었습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },
        // showAlert_logout() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '로그아웃 되었습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },    
        // showAlert_NoUser() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '사용권한이 없습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },                           
                     
               
    }
}




</script>

<style scoped>
.scroll {
    height: 150px;
    overflow-y: scroll;
}
button {
    width: 100px;
}
/* .flrInfo.atclFetrDesc.gap_rate2.gap_rate2 {
    font-size: 0.5em;
} */
.loading {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
</style>
