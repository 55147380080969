<template>

<div id="app">

<!-- Modal부분 -->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><b>{{ this.sigun }} 주간등락단지 목록</b>(조사기준일:{{ ymd.replace(/-/g, '.') }} ~ {{ ymd_.replace(/-/g, '.') }})</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="bg-primary text-white text-start p-1">매매상승 상위 {{ MupDanjiList2.length }}개 단지 <div class="float-end">
          <i class="bi bi-code-square"></i> 부동산빅데이터땅짚고</div></div>
        <table id="updown1" class="table table-bordered updown">
          <thead>
            <tr>
              <th>순위</th>
              <th>읍면동</th>
              <th>단지</th>
              <th>매매</th>
              <th>전세</th>
              <th>세대수</th>
              <th>연차</th>
              <th>gap</th>
              <th>전세가율</th>
            </tr>
          </thead>
          <tbody  v-for="td,i in MupDanjiList2" :key="i">
            <tr>
              <td>{{ i+1 }}</td>
              <td>{{ td.dong }}</td>
              <td>{{ td.단지명 }}{{ td.전용면적 }}({{ td.공급면적평 }}평)</td>
              <td>{{ td.매매시세 }}억원(<span class="text-danger">{{ td.m_dt }}%</span>)</td>
              <td>{{ td.전세시세 }}억원({{ td.j_dt }}%)</td>
              <td>{{ td.세대수 }}</td>
              <td>{{ td.연차 }}</td>
              <td>{{ td.매매전세차 }}</td>
              <td>{{ td.전세가율 }}</td>
            </tr>
          </tbody>
        </table>
        <div class="bg-primary text-white text-start p-1">매매하락 상위 {{ MdownDanjiList2.length }}개 단지 <div class="float-end">
          <i class="bi bi-code-square"></i> 부동산빅데이터땅짚고
        </div></div>
        <table id="updown2" class="table table-bordered updown">
          <thead>
            <tr>
              <th>순위</th>
              <th>읍면동</th>
              <th>단지</th>
              <th>매매</th>
              <th>전세</th>
              <th>세대수</th>
              <th>연차</th>
              <th>gap</th>
              <th>전세가율</th>
            </tr>
          </thead>
          <tbody v-for="td,i in MdownDanjiList2" :key="i">
            <tr>
              <td>{{ i+1 }}</td>
              <td>{{ td.dong }}</td>
              <td>{{ td.단지명 }}{{ td.전용면적 }}({{ td.공급면적평 }}평)</td>
              <td>{{ td.매매시세 }}억원(<span class="text-primary">{{ td.m_dt }}%</span>)</td>
              <td>{{ td.전세시세 }}억원({{ td.j_dt }}%)</td>
              <td>{{ td.세대수 }}</td>
              <td>{{ td.연차 }}</td>
              <td>{{ td.매매전세차 }}</td>
              <td>{{ td.전세가율 }}</td>
            </tr>
          </tbody>
        </table>
        <div class="bg-primary text-white text-start p-1">전세상승 상위 {{ JupDanjiList2.length }}개 단지 <div class="float-end">
          <i class="bi bi-code-square"></i> 부동산빅데이터땅짚고
        </div></div>
        <table id="updown3" class="table table-bordered updown">
          <thead>
            <tr>
              <th>순위</th>
              <th>읍면동</th>
              <th>단지</th>
              <th>매매</th>
              <th>전세</th>
              <th>세대수</th>
              <th>연차</th>
              <th>gap</th>
              <th>전세가율</th>
            </tr>
          </thead>
          <tbody v-for="td,i in JupDanjiList2" :key="i">
            <tr>
              <td>{{ i+1 }}</td>
              <td>{{ td.dong }}</td>
              <td>{{ td.단지명 }}{{ td.전용면적 }}({{ td.공급면적평 }}평)</td>
              <td>{{ td.매매시세 }}억원({{ td.m_dt }}%)</td>
              <td>{{ td.전세시세 }}억원(<span class="text-danger">{{ td.j_dt }}%</span>)</td>
              <td>{{ td.세대수 }}</td>
              <td>{{ td.연차 }}</td>
              <td>{{ td.매매전세차 }}</td>
              <td>{{ td.전세가율 }}</td>
            </tr>
          </tbody>
        </table>
        <div class="bg-primary text-white text-start p-1">전세하락 상위 {{ JdownDanjiList2.length }}개 단지 <div class="float-end">
          <i class="bi bi-code-square"></i> 부동산빅데이터땅짚고
        </div></div>
        <table id="updown4" class="table table-bordered updown">
          <thead>
            <tr>
              <th>순위</th>
              <th>읍면동</th>
              <th>단지</th>
              <th>매매</th>
              <th>전세</th>
              <th>세대수</th>
              <th>연차</th>
              <th>gap</th>
              <th>전세가율</th>
            </tr>
          </thead>
          <tbody v-for="td,i in JdownDanjiList2" :key="i">
            <tr>
              <td>{{ i+1 }}</td>
              <td>{{ td.dong }}</td>
              <td>{{ td.단지명 }}{{ td.전용면적 }}({{ td.공급면적평 }}평)</td>
              <td>{{ td.매매시세 }}억원({{ td.m_dt }}%)</td>
              <td>{{ td.전세시세 }}억원(<span class="text-primary">{{ td.j_dt }}%</span>)</td>
              <td>{{ td.세대수 }}</td>
              <td>{{ td.연차 }}</td>
              <td>{{ td.매매전세차 }}</td>
              <td>{{ td.전세가율 }}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success float-end" @click="downloadExcelupdown()">
          <i class="bi bi-file-earmark-excel"></i> Excel 다운로드
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal부분 -->
  <div v-if="$isMobile()">
    본 Dash-Board는 반드시 PC에서 실행해주세요!!
  </div>


  <div v-else>

          <div v-if="isLoading" class="loading-container">
            <div class="loading">
              <Fade-loader />
            </div>
          </div>
      
      
          <div class="container-fluid">
            <div class="alert alert-secondary mt-3 mb-1 pt-2 pb-2 text-start fs-3" role="alert">
              <b>주간등락단지조회</b>
                  <button type="button" class="btn btn-success float-end" @click="downloadExcelt()">
                    <i class="bi bi-file-earmark-excel"></i> Excel 다운로드
                  </button>
    
      
      
            </div>
            <div class="mt-0 mb-2">
      


              <table id="mytb" class="table table-hover table-bordered">
                <thead>

                  <tr>
                    <th colspan="12" class="bg-dark text-light text-start"><i class="bi bi-code-square"></i> 부동산빅데이터땅짚고<div class="float-end">
                        조사주간: {{ ymd.replace(/-/g, '.') }} ~ {{ ymd_.replace(/-/g, '.') }}</div>
                    </th>
                  </tr>
                  <tr class="bg-dark bg-gradient text-white">
                    <th colspan="4">소재지</th>
                    <th colspan="4">매매</th>
                    <th colspan="4">전세</th>
                  </tr>
                  <tr role="button" class="bg-dark bg-gradient text-white">
                    <th scope="col" @click="doSort('no')">No<span v-if="(sort.desc) && (sort.field == 'no')"><i class="bi float-end bi-sort-down"></i></span><span v-else-if="(sort.desc==false) && (sort.field == 'no')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('sido')">시도<span v-if="(sort.desc) && (sort.field == 'sido')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'sido')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('sigun2')">시군구<span v-if="(sort.desc) && (sort.field == 'sigun2')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'sigun2')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('ingu')">인구<span v-if="(sort.desc) && (sort.field == 'ingu')"><i
                          class="bi float-end bi-sort-down"></i></span><span v-else-if="(sort.desc==false) && (sort.field == 'ingu')"><i
                          class="bi float-end bi-sort-up"></i></span><span v-else><i class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('m_up_cnt')">상승단지<span v-if="(sort.desc) && (sort.field == 'm_up_cnt')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'm_up_cnt')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('m_zero_cnt')">보합단지<span v-if="(sort.desc) && (sort.field == 'm_zero_cnt')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'm_zero_cnt')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('m_down_cnt')">하락단지<span v-if="(sort.desc) && (sort.field == 'm_down_cnt')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'm_down_cnt')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('m_up_index')">등락지수<span v-if="(sort.desc) && (sort.field == 'm_up_index')"><i class="bi float-end bi-sort-down"></i></span><span
                      v-else-if="(sort.desc==false) && (sort.field == 'm_up_index')"><i class="bi float-end bi-sort-up"></i></span><span v-else><i
                        class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('j_up_cnt')">상승단지<span v-if="(sort.desc) && (sort.field == 'j_up_cnt')"><i
                          class="bi float-end bi-sort-down"></i></span><span v-else-if="(sort.desc==false) && (sort.field == 'j_up_cnt')"><i
                          class="bi float-end bi-sort-up"></i></span><span v-else><i class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('j_zero_cnt')">보합단지<span v-if="(sort.desc) && (sort.field == 'j_zero_cnt')"><i
                          class="bi float-end bi-sort-down"></i></span><span
                        v-else-if="(sort.desc==false) && (sort.field == 'j_zero_cnt')"><i class="bi float-end bi-sort-up"></i></span><span
                        v-else><i class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('j_down_cnt')">하락단지<span v-if="(sort.desc) && (sort.field == 'j_down_cnt')"><i
                          class="bi float-end bi-sort-down"></i></span><span
                        v-else-if="(sort.desc==false) && (sort.field == 'j_down_cnt')"><i class="bi float-end bi-sort-up"></i></span><span
                        v-else><i class="bi float-end bi-justify"></i></span></th>
                    <th scope="col" @click="doSort('j_up_index')">등락지수<span v-if="(sort.desc) && (sort.field == 'j_up_index')"><i
                          class="bi float-end bi-sort-down"></i></span><span
                        v-else-if="(sort.desc==false) && (sort.field == 'j_up_index')"><i class="bi float-end bi-sort-up"></i></span><span
                        v-else><i class="bi float-end bi-justify"></i></span></th>
                  </tr>
                </thead>
                <tbody v-for="td in sortedData" :key="td">
      
                  <tr  role="button" @click="clicked(td)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <td class="bg-dark bg-gradient text-white">{{td.no}}</td>
                    <td>{{ td.sido }}</td>
                    <td>{{ td.sigun2 }}</td>
                    <td :title="`${td.ingu.toLocaleString('ko-KR')}명`"><div style="position: relative; min-height: 1.5em; text-align: right;"><span :style="`position: absolute; top: 0px; left: 0px; right: 0px; z-index: 0; display: block; height: 100%; width: ${td.ingu/td.max_ingu*100}%; background-color: rgba(0, 204, 102, 0.3); text-align: right;`"></span>{{ Math.ceil(td.ingu/10000) }}만</div></td>

                    <td v-if="td.m_up_cnt==0"><div class="bg-secondary bg-opacity-10 text-end">상승단지없음</div></td>
                    <td role="button" v-else><div class="bg-danger bg-opacity-25 text-end">{{ td.m_up_cnt.toLocaleString('ko-KR') }}단지 상승</div></td>

                    <td><div class="bg-secondary bg-opacity-10 text-end">{{ td.m_zero_cnt.toLocaleString('ko-KR') }}단지 보합</div></td>

                    <td v-if="td.m_down_cnt==0"><div class="bg-secondary bg-opacity-10 text-end">하락단지없음</div></td>
                    <td role="button" v-else><div class="bg-primary bg-opacity-25 text-end">{{ td.m_down_cnt.toLocaleString('ko-KR') }}단지 하락</div></td>

                    <td v-if="(getQuartile(td.m_up_index)<-7)||(getQuartile(td.m_up_index)>9)" ><div :style="`background-color:${getColor(td.m_up_index,getQuartile(td.m_up_index))}`"><span class="text-light">{{ td.m_up_index.toFixed(2) }}</span></div></td>
                    <td v-else><div :style="`background-color:${getColor(td.m_up_index,getQuartile(td.m_up_index))}`">{{td.m_up_index.toFixed(2) }}</div></td>

                    <td v-if="td.j_up_cnt==0"><div class="bg-secondary bg-opacity-10 text-end">상승단지없음</div></td>
                    <td role="button" v-else><div class="bg-danger bg-opacity-25 text-end">{{ td.j_up_cnt.toLocaleString('ko-KR') }}단지 상승</div></td>
                    
                    <td><div class="bg-secondary bg-opacity-10 text-end">{{ td.j_zero_cnt.toLocaleString('ko-KR') }}단지 보합</div></td>
                    
                    <td v-if="td.j_down_cnt==0"><div class="bg-secondary bg-opacity-10 text-end">하락단지없음</div></td>
                    <td role="button" v-else><div class="bg-primary bg-opacity-25 text-end">{{ td.j_down_cnt.toLocaleString('ko-KR') }}단지 하락</div></td>

                    <td v-if="(getQuartile(td.j_up_index)<-7)||(getQuartile(td.j_up_index)>9)">
                      <div :style="`background-color:${getColor(td.j_up_index,getQuartile(td.j_up_index))}`"><span class="text-light">{{
                      td.j_up_index.toFixed(2) }}</span></div>
                    </td>
                    <td v-else>
                      <div :style="`background-color:${getColor(td.j_up_index,getQuartile(td.j_up_index))}`">{{td.j_up_index.toFixed(2) }}
                      </div>
                    </td>
                  </tr>
    
      
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="12" class="bg-dark text-light text-start"><i class="bi bi-code-square"></i> 부동산빅데이터땅짚고</th>
                  </tr>
                </tfoot>
              </table>

            </div>
      
      

          </div>

  </div>


</div>


</template>

<script>
// import the styles
// import 'vue-good-table-next/dist/vue-good-table-next.css'
// import { VueGoodTable } from 'vue-good-table-next';

// import $ from 'jquery/dist/jquery.slim';
// import DataTable from 'datatables.net-vue3/dist/datatables.net-vue.esm';

// import the component
// import Treeselect from 'vue3-treeselect'
// import the styles
// import 'vue3-treeselect/dist/vue3-treeselect.css'
import * as XLSX from 'xlsx';

// import { Buffer } from 'buffer';

//로딩바
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'



export default {
  components: {
    FadeLoader,
    // VueGoodTable,
  },
  computed: {
    user() {
        return this.$store.state.user;
    },
    sortedData() {
        // let data=[];
        // for (let i = 0; i < this.selectedCake.length; i++) {
        //     data.push(...this.UpDownIndexList.filter(item => item.지역코드 === this.selectedCake[i]))
        // }

        let data=this.UpDownIndexList

        let dataList = [];
        let inguList=[]
        for (let i = 0; i < data.length; i++) {
            dataList.push(Number(data[i].m_up_index))
            inguList.push(Number(data[i].ingu))

        }      
        let max = Math.max(...dataList)  
        let max_ingu = Math.max(...inguList)   
        let UpDownIndexList2 = [];
        for (let i = 0; i < data.length; i++) {

            // let m_up_index1 = Math.round(data[i].m_up_index).toLocaleString('ko-KR')
            // data[i]['m_up_index1']= m_up_index1

            data[i]['max']= max
            data[i]['max_ingu']= max_ingu

            // console.log(data[i])
            UpDownIndexList2.push(data[i])
            // console.log(UpDownIndexList[i].min)
            // this.UpDownIndexList = UpDownIndexList;
        }    
        if(!this.sort.field){
            return UpDownIndexList2
        }
       

        return UpDownIndexList2.concat().sort((a,b)=>{
            if(this.sort.desc){
                return a[this.sort.field] > b[this.sort.field] ? -1:1        
            }else{
                return a[this.sort.field] > b[this.sort.field] ? 1:-1                  
            }


        })
    },      
    MupDanjiList2() {
      let MupDanjiList2=this.MupDanjiList
      return [...MupDanjiList2].filter(item => item.sigun_code === parseInt(this.sigun_code))
    },
    MdownDanjiList2() {
      let MdownDanjiList2=this.MdownDanjiList
      return [...MdownDanjiList2].filter(item => item.sigun_code === parseInt(this.sigun_code))
    },        
    JupDanjiList2() {
      let JupDanjiList2=this.JupDanjiList
      return [...JupDanjiList2].filter(item => item.sigun_code === parseInt(this.sigun_code))
    },        
    JdownDanjiList2() {
      let JdownDanjiList2=this.JdownDanjiList
      return [...JdownDanjiList2].filter(item => item.sigun_code === parseInt(this.sigun_code))
    },                            
  },  
  mounted() {
    //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
      // if (this.user.pro_user != 1) {
      //     this.showAlert_NoUser();
      //     this.$router.push({ path: '/' });
      // }      
  },
  created() {

      
      this.getUpDownIndexList();

      this.getMupDanjiList();
      this.getMdownDanjiList();
      this.getJupDanjiList();
      this.getJdownDanjiList();

      this.getSidoList();
      this.getSigunList();
      this.getSigunList1();
  },  
  data() {
    return{
      isLoading: false,
      UpDownIndexList: [],

      MupDanjiList: [],
      MdownDanjiList: [],
      JupDanjiList: [],
      JdownDanjiList: [],


      DownDanjiList: [],
      SidoList: [],
      SigunList: [],
      SigunList1: [],
      DongList: [],
      Sigun1: [],
      Sigun2: [],
      Sigun3: [],
      sido: "경상남도",
      sigun: "진주시",
      sigun_code: 48170,
      clicked_row:47190,
      sigun2: "진주시",
      dong: "",
      myNum:30,
      avgMax:"",
      selectedCake: [48170],         

      value: null,
      sort: {
          field: 'no',
          desc: false        
      },
      excel: [],    

      valueConsistsOf: 'LEAF_PRIORITY',
      sortValueBy: 'LEVEL',
      updateDate: "",
      blueList:['#f2f2ff', '#e6e6ff', '#d9d9ff', '#ccccff', '#bfbfff', '#b2b2ff', '#a6a6ff', '#9999ff', '#8c8cff', '#8080ff', '#7373ff', '#6666ff', '#5959ff', '#4d4dff', '#4040ff', '#3333ff', '#2626ff', '#1919ff', '#0d0dff', '#0000ff'],
      redList:['#fff2f2', '#ffe6e6', '#ffd9d9', '#ffcccc', '#ffbfbf', '#ffb2b2', '#ffa6a6', '#ff9999', '#ff8c8c', '#ff8080', '#ff7373', '#ff6666', '#ff5959', '#ff4d4d', '#ff4040', '#ff3333', '#ff2626', '#ff1919', '#ff0d0d', '#ff0000'],    
         
      rows: [
        { id:1, name:"John", age: 20, createdAt: '',score: 0.03343 },
        { id:2, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
        { id:3, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
        { id:4, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
        { id:5, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
        { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
      ],
      // vue-good-table-next      

      checkbox: false,
      checkbox1: false,

      pym2: '평당가',
      noise: '노이즈OFF',

      styleClass: 'vgt-table bordered fs-5',
      names: [
        {
          "name":"Amy",
          "age":37
        },
        {
          "name":"James",
          "age":39
        }
      ],
      currentItem: false,
      ymd:'',
      ymd_:'',      
    };
  },
  methods: {

    async getUpDownIndexList() {
        let UpDownIndexList = await this.$api("/api/UpDownIndexList",'post',{})
        this.UpDownIndexList = UpDownIndexList;

        this.ymd = this.UpDownIndexList[0].ymd
        this.ymd_ = this.UpDownIndexList[0].ymd_    

    },

    async getMupDanjiList() {
      this.isLoading = true
      let MupDanjiList = await this.$api("/api/MupDanjiList",'post', { })
      this.MupDanjiList = MupDanjiList;
      this.isLoading = false
        
    },
    async getMdownDanjiList() {
      this.isLoading = true
      let MdownDanjiList = await this.$api("/api/MdownDanjiList",'post', { })
      this.MdownDanjiList = MdownDanjiList;
      this.isLoading = false
      // console.log(JSON.parse((Buffer.from(this.MdownDanjiList[0].data)).toString('utf-8').replace(/'/g, '"')).I2010.단지명)
    },  
    async getJupDanjiList() {
      this.isLoading = true
      let JupDanjiList = await this.$api("/api/JupDanjiList",'post', { })
      this.JupDanjiList = JupDanjiList;
      this.isLoading = false
      // console.log(JSON.parse((Buffer.from(this.JupDanjiList[0].data)).toString('utf-8').replace(/'/g, '"')).I2010.단지명)
    },  
    async getJdownDanjiList() {
      this.isLoading = true
      let JdownDanjiList = await this.$api("/api/JdownDanjiList",'post', { })
      this.JdownDanjiList = JdownDanjiList;
      this.isLoading = false
      // console.log(JSON.parse((Buffer.from(this.JdownDanjiList[0].data)).toString('utf-8').replace(/'/g, '"')).I2010.단지명)
    },  

    async getSidoList() {
        let SidoList = await this.$api("/api/SidoList",'post', {});
        this.SidoList = SidoList;
    },
    async getSigunList() {
        let SigunList = await this.$api("/api/SigunList",'post', {});
        this.SigunList = SigunList;
    },
    async getSigunList1() {
        let SigunList1 = await this.$api("/api/SigunList1",'post', { param: [this.sido] });
        this.SigunList1 = SigunList1;
    },
    changeSido() {
        // this.$router.push({ path: '/agt' });
        this.sido = '대구광역시';
        this.getUsers();
    },
    changeSigun() {
        // console.log(a)
        this.$router.push({ path: '/updown'});
    },
    showAlert() {
      this.$swal.fire({
          icon: 'warning',
          title: '로그인이 필요합니다.',
      });
    },
    showAlert_login() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그인 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },
    showAlert_logout() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그아웃 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },    
    showAlert_NoUser() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '사용권한이 없습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },          
    sidoChange() {
      this.sigun='구미시';
    },

    changeMode() {
      this.modeBool = !this.modeBool;
      this.config.checkMode = this.modeBool ? 0 : 1;
    },

    getFormatDate(date) {
      const year = date.getFullYear();
      const month = (1 + date.getMonth());
      const day = date.getDate();
      return `${year}년 ${month}월 ${day}일 `; //Template literals 이용
    },
    doSort(field) {
        if(field == this.sort.field){
            this.sort.desc = !this.sort.desc
        }else{
            this.sort.field = field;
            this.sort.desc = true;
        }
    },
    getQuartile(n) {
        //console.log(this.UpDownIndexList[0].x)
        let dataList_m = [];
        let dataList_j = [];

        // let dataListAbs = [];

        for (let i = 0; i < this.UpDownIndexList.length; i++) {
            dataList_m.push(Number(this.UpDownIndexList[i].m_up_index))
            dataList_j.push(Number(this.UpDownIndexList[i].j_up_index))

            // dataListAbs.push(Math.abs(Number(this.UpDownIndexList[i].m_up_index)))
        }
        let min_m = Math.abs(100-Math.min(...dataList_m))
        let max_m = Math.abs(100-Math.max(...dataList_m))
        let min_j = Math.abs(100-Math.min(...dataList_j))
        let max_j = Math.abs(100-Math.max(...dataList_j))        
        let max2 = Math.max(min_m,max_m,min_j,max_j)
        // let max2 = Math.max(...dataList)
        // console.log(max)
        // console.log(max2)

        let quartile = Math.ceil( Math.abs(n-100)*20/ max2)
        // console.log(n)
        // console.log(Math.ceil(0.1250331 * (20 / max)))
        // console.log(quartile)
        if (n != 0) {
            return quartile                
        } else if (n == 0) {
            return 'white'
        } 
    },
    getColor(i,n) {
        if (i>100) {
            return this.redList[n-1]
        } else if (i<100) {
            return this.blueList[n-1]
        } else {
            return 'white'
        }
    },
    toggleCheckbox() {
      this.checkbox = !this.checkbox
      if(this.checkbox==false) {
        this.pym2='평당가';
        // this.columns[6].hidden = false;
      } else {
        this.pym2='㎡당가';
        // this.columns[6].hidden = true;
      }
      // this.$emit('setCheckboxVal', this.checkbox)
    },
    toggleCheckbox1() {
      this.checkbox1 = !this.checkbox1
      if(this.checkbox1==false) {
        this.noise='노이즈OFF';
        this.columns[3].hidden = true;
        this.columns[5].hidden = true;
        this.columns[8].hidden = true;
        this.styleClass ='vgt-table bordered fs-5';        
      } else {
        this.noise='노이즈ON';
        this.columns[3].hidden = false;
        this.columns[5].hidden = false;
        this.columns[8].hidden = false;
        this.styleClass ='vgt-table bordered fs-6';        
      }

    },
    rowStyleClassFn(row) {
      return row.m_up_index > 100 ? 'green' : 'red';
    },
    downloadExcel(){
      var excelData = XLSX.utils.json_to_sheet(this.UpDownIndexList); // this.items 는 json object 를 가지고있어야함 
      var workBook = XLSX.utils.book_new(); // 새 시트 생성 
      XLSX.utils.book_append_sheet(workBook, excelData, '가입자 현황'); // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, 'account_statistics.xlsx'); // 엑셀파일 만듬
    },
    downloadExcelt(){
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('mytb')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성 
      XLSX.utils.book_append_sheet(workBook, excelData, `주간등락단지현황(${this.ymd.replace(/-/g, '')}기준)`);  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `주간등락단지현황(${this.ymd.replace(/-/g, '')}기준).xlsx`); // 엑셀파일 만듬
    }, 
    downloadExcelupdown(){

      var workBook = XLSX.utils.book_new(); // 새 워크북 생성

      var excelData1 = XLSX.utils.table_to_sheet(document.getElementById('updown1')); // table id를 넣어주면된다
      XLSX.utils.book_append_sheet(workBook, excelData1, `매매상승`);  // 시트 명명, 데이터 지정, 워크북에추가      

      var excelData2 = XLSX.utils.table_to_sheet(document.getElementById('updown2')); // table id를 넣어주면된다
      XLSX.utils.book_append_sheet(workBook, excelData2, `매매하락`);  // 시트 명명, 데이터 지정, 워크북에추가    
      
      var excelData3 = XLSX.utils.table_to_sheet(document.getElementById('updown3')); // table id를 넣어주면된다
      XLSX.utils.book_append_sheet(workBook, excelData3, `전세상승`);  // 시트 명명, 데이터 지정, 워크북에추가      

      var excelData4 = XLSX.utils.table_to_sheet(document.getElementById('updown4')); // table id를 넣어주면된다
      XLSX.utils.book_append_sheet(workBook, excelData4, `전세하락`);  // 시트 명명, 데이터 지정, 워크북에추가        


      XLSX.writeFile(workBook, `${this.sigun} 등락단지(${this.ymd.replace(/-/g, '')}기준).xlsx`); // 엑셀파일 만듬
    },     
    clicked(row) {
      this.sigun_code = row.area_code5;
      this.sigun = row.sigun;

      this.clicked_row = row.area_code5;
      // this.MupDanjiList=[...this.MupDanjiList].filter(item => item.sigun_code === row.area_code5)
    }    
    // showModal(){
      
    //   // this.currentItem=item
    //   // console.log(item)
    //   // this.getMupDanjiList(parseInt(sigun_code));

    // }       
  
  },
  
};

</script>

<style scoped>
td {
  padding: 3px !important;
}
.updown {
  font-size: 0.8em;
}
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
</style>