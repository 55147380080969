import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import VueMobileDetection from 'vue-mobile-detection'
// import BackToTop from 'vue-backtotop-ssr'
// import 'vue-backtotop-ssr/src/styles.css' // if needed
import * as d3 from 'd3';
// createApp.prototype.$d3 = d3;

import VueClipboard from 'vue3-clipboard'

import VueCookies from 'vue3-cookies'

import { globalCookiesConfig } from "vue3-cookies";

globalCookiesConfig({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});

const app = createApp(App);

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(VueCookies);
app.use(VueMobileDetection);
app.use(router);
app.mixin(mixins);
app.use(store);
app.use(d3);
app.use(VueSweetalert2);
app.mount('#app');
this.$cookies.set('theme','default');
// window.Kakao.init("b32066df2a24d34c69af3c7c3077e78a");


