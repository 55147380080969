<template>
    <div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="row">
                    <div class="input-group mb-1 justify-content-md-center">
                        <!-- <span class="input-group-text bg-success text-white">주간,월간 변동률을 증감지도로 분석해 보아요!!</span> -->
                        <!-- <input type="radio" class="btn-check" v-model="selected_gubun" value="week" name="options-outlined" id="success-outlined" autocomplete="off">
                        <label class="btn btn-outline-success" for="success-outlined">주간</label>
                        <input type="radio" class="btn-check" v-model="selected_gubun" value="mon" name="options-outlined" id="danger-outlined" autocomplete="off">
                        <label class="btn btn-outline-danger" for="danger-outlined">월간</label> -->

                        <div class="col-auto">
                            <select class="form-select" v-model="selected_gubun" @change="changeGubun(selected_gubun)">
                                <option value="week">주간</option>
                                <option value="mon">월간</option>
                            </select>
                        </div>
                        <!-- <div class="col-auto">
                            <select class="form-select" v-model="selected_firm" @change="changeFirm(selected_firm)">
                                <option value="api_series_kb_week">KB부동산-주간</option>
                                <option value="api_series_r1_week">한국부동산원-주간</option>
                                <option value="api_series_kb_mon">KB부동산-월간</option>
                                <option value="api_series_r1_mon">한국부동산원-월간</option>
                            </select>
                        </div> -->
                        <div class="col-auto">
                            <select class="form-select" v-model="selected_firm" @change="changeFirm(selected_firm)">
                                <option value="kb">KB부동산</option>
                                <option value="r1">한국부동산원</option>
                            </select>
                        </div>
                        <button class="btn btn-warning" type="button" @click="firstday"><i class="bi bi-skip-backward-fill"></i> 처음</button>
                        <button v-if="this.num == this.date_list.length-1" class="btn btn-secondary" type="button" @click="minus"><i class="bi bi-caret-left-fill"></i>이전</button>
                        <button v-else class="btn btn-primary" type="button" @click="minus"><i class="bi bi-caret-left-fill"></i>이전</button>
                        <div class="col-auto">
                            <select id="select1" class="form-select" v-model="selected_date" @change="changeDate(selected_date)">
                                <option :value="a.ymd" :key=i v-for="(a,i) in YmdList">
                                    {{a.ymd}}
                                </option>
                            </select>
                        </div>
                        <button v-if="this.num==0" class="btn btn-secondary" type="button" @click="plus">이후<i class="bi bi-caret-right-fill"></i></button>
                        <button v-else class="btn btn-danger" type="button" @click="plus">이후<i class="bi bi-caret-right-fill"></i></button>
                        <button class="btn btn-warning" type="button" id="mybtn" @click="lastday">마지막 <i class="bi bi-skip-forward-fill"></i></button>
                        <span class="input-group-text">기준 증감지도</span>
                        
                    </div>

                </div>
            </div>
        </div>

            <div class="one">
                <!-- {{ KrheatmapListSido_all }}{{ KrheatmapList_all }} -->
                <!-- {{ KrheatmapList_all }} -->
                <div class="trade">
                    <div>
                        <h6>권역별</h6>
                        <KrMapSido :KrheatmapList="KrheatmapListSido_m" :KrheatmapList_all="KrheatmapList_all" :XList="XListSido" :YList="YListSido.slice(0,27)" :selected_firm="selected_firm" />
                    </div>
                    <div class="pe-1">
                        <!-- {{ provider() }} {{ selected_date}}기준 매매증감 -->
                        <h5><b><span style="font-size: smaller;">{{ gubun() }}</span><span style="font-size: larger;">{{ provider() }}</span> <span style="color:gray">{{ selected_date}}기준</span> <span style="font-size: larger;color:blue">매매증감</span></b></h5>
                        <KrMap :KrheatmapList="KrheatmapList_m" :KrheatmapList_all="KrheatmapList_all" :XList="XList.slice(0,13)" :YList="YList" />
                    </div>
                </div>
                <div class="rent">
                    <div>
                        <h6>권역별</h6>
                        <KrMapSido :KrheatmapList="KrheatmapListSido_j" :KrheatmapList_all="KrheatmapList_all" :XList="XListSido" :YList="YListSido.slice(0, 27)" :selected_firm="selected_firm"/>
                    </div>
                    <div class="pe-1">
                        <!-- {{ provider() }} {{ selected_date}}기준 전세증감 -->
                        <h5><b><span style="font-size: smaller;">{{ gubun() }}</span><span style="font-size: larger;">{{ provider() }}</span> <span
                            style="color:gray">{{ selected_date}}기준</span> <span style="font-size: larger;color:red">전세증감</span></b></h5>
                        <KrMap :KrheatmapList="KrheatmapList_j" :KrheatmapList_all="KrheatmapList_all" :XList="XList.slice(0, 13)" :YList="YList" />
                    </div>
                </div>
            </div>

    </div>
</template>


<script>
import $ from 'jquery/dist/jquery.slim';
import KrMap from '../components/KrMap.vue'
import KrMapSido from '../components/KrMapSido.vue'

import cloneDeep from 'lodash.clonedeep'


export default {
    components:{
        KrMap,
        KrMapSido
    },
    computed: {
        // user() {
        //     return this.$store.state.user;
        // }
        KrheatmapListSido_m() {
            // let f = this.selected_firm
            // let d = this.selected_date
            let KrheatmapListSido = cloneDeep(this.KrheatmapListSido)
            for (let i = 0; i < KrheatmapListSido.length; i++) {
                if((this.selected_date=='2023-01-30')&&(this.selected_firm=='kb')&&(this.selected_gubun=='week')){
                    KrheatmapListSido[i].data= KrheatmapListSido[i].m_dt/2
                } else {
                    KrheatmapListSido[i].data= KrheatmapListSido[i].m_dt
                }
                KrheatmapListSido[i].gubun= "m_dt"            

                delete KrheatmapListSido[i].m_dt;
                delete KrheatmapListSido[i].j_dt;
                delete KrheatmapListSido[i].m_js;
                delete KrheatmapListSido[i].j_js;                
            }
            // return KrheatmapListSido
            if(this.selected_firm.includes('kb')) {
                return [...KrheatmapListSido.slice(0,26),...KrheatmapListSido.slice(27,28)]
            } else {
                return [...KrheatmapListSido.slice(0,27)]
            }
        },
        KrheatmapListSido_j() {
            // let f = this.selected_firm
            // let d = this.selected_date
            let KrheatmapListSido = cloneDeep(this.KrheatmapListSido)
            for (let i = 0; i < KrheatmapListSido.length; i++) {
                if((this.selected_date=='2023-01-30')&&(this.selected_firm=='kb')&&(this.selected_gubun=='week')){
                    KrheatmapListSido[i].data= KrheatmapListSido[i].j_dt/2
                } else {
                    KrheatmapListSido[i].data= KrheatmapListSido[i].j_dt
                }
                KrheatmapListSido[i].gubun= "j_dt"            

                delete KrheatmapListSido[i].m_dt;
                delete KrheatmapListSido[i].j_dt;
                delete KrheatmapListSido[i].m_js;
                delete KrheatmapListSido[i].j_js;                
            }
            // return KrheatmapListSido

            if(this.selected_firm.includes('kb')) {
                return [...KrheatmapListSido.slice(0,26),...KrheatmapListSido.slice(27,28)]
            } else {
                return [...KrheatmapListSido.slice(0,27)]
            }
        },
        // KrheatmapListSido_all() {
        //     let KrheatmapListSido_all=[]
        //     let KrheatmapListSidom = cloneDeep(this.KrheatmapListSido)
        //     for (let i = 0; i < KrheatmapListSidom.length; i++) { 
        //         if(KrheatmapListSidom[i].m_dt){
        //             KrheatmapListSido_all.push(KrheatmapListSidom[i].m_dt) 
        //         }
        //         if(KrheatmapListSidom[i].j_dt){
        //             KrheatmapListSido_all.push(KrheatmapListSidom[i].j_dt)
        //         }                          
        //     }
        //     return KrheatmapListSido_all            
        // },

        KrheatmapList_m() {
            // let f = this.selected_firm
            // let d = this.selected_date
            let KrheatmapList = cloneDeep(this.KrheatmapList)
            for (let i = 0; i < KrheatmapList.length; i++) {
                if((this.selected_date=='2023-01-30')&&(this.selected_firm=='kb')&&(this.selected_gubun=='week')){
                    KrheatmapList[i].data= KrheatmapList[i].m_dt/2
                } else {
                    KrheatmapList[i].data= KrheatmapList[i].m_dt
                }
                KrheatmapList[i].gubun= "m_dt"            

                delete KrheatmapList[i].m_dt;
                delete KrheatmapList[i].j_dt;
                delete KrheatmapList[i].m_js;
                delete KrheatmapList[i].j_js;                
            }
            return KrheatmapList
        },
        KrheatmapList_j() {
            // let f = this.selected_firm
            // let d = this.selected_date
            let KrheatmapList = cloneDeep(this.KrheatmapList)
            for (let i = 0; i < KrheatmapList.length; i++) {
                if((this.selected_date=='2023-01-30')&&(this.selected_firm=='kb')&&(this.selected_gubun=='week')){
                    KrheatmapList[i].data= KrheatmapList[i].j_dt/2
                } else {
                    KrheatmapList[i].data= KrheatmapList[i].j_dt
                }
                KrheatmapList[i].gubun= "j_dt"            

                delete KrheatmapList[i].m_dt;
                delete KrheatmapList[i].j_dt;
                delete KrheatmapList[i].m_js;
                delete KrheatmapList[i].j_js;                
            }
            return KrheatmapList
        },
        KrheatmapList_all() {
            // let KrheatmapList_all=[]
            // let KrheatmapListm = cloneDeep(this.KrheatmapList)
            // for (let i = 0; i < KrheatmapListm.length; i++) { 
            //     if(KrheatmapListm[i].m_dt){
            //         KrheatmapList_all.push(KrheatmapListm[i].m_dt) 
            //     }
            //     if(KrheatmapListm[i].j_dt){
            //         KrheatmapList_all.push(KrheatmapListm[i].j_dt)
            //     }                          
            // }
            // let KrheatmapListSidom = cloneDeep(this.KrheatmapListSido)
            // for (let i = 0; i < KrheatmapListSidom.length; i++) { 
            //     if(KrheatmapListSidom[i].m_dt){
            //         KrheatmapList_all.push(KrheatmapListSidom[i].m_dt) 
            //     }
            //     if(KrheatmapListSidom[i].j_dt){
            //         KrheatmapList_all.push(KrheatmapListSidom[i].j_dt)
            //     }                          
            // }            
            // return KrheatmapList_all
            if(this.selected_gubun=='week'){
                return [-2.2,2.2]            
            } else {
                return [-8.8,8.8]            
            }
        }       
    },
    mounted() {
        // //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
        // if (this.user != 1) {
        //     this.showAlert_NoUser();
        //     this.$router.push({ path: '/' });
        // }     
        // this.selected_date = this.lastdate
        // console.log(this.YmdList)
        // this.selected_date=this.YmdList[0].ymd
        // this.changeDate(0)
        // this.aaaa()

        $(document).ready(function(){
            // console.log("오주님")
            setTimeout(() => $("#mybtn").click(), 500);
        });        
        
    },
    // beforeCreate() {
    //     console.log('api_series_kb_week');
    // },
    created() {
        this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);
        this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);

        this.getYmdList("api_series_"+this.selected_firm+"_"+this.selected_gubun);
        this.getXListSido();
        this.getYListSido();
        this.getXList();
        this.getYList();      
    },
    data() {
        return {
            radioValues:'week',
            selected_gubun:'week',
            lastdate:'',
            date_list:[],
            // selected: 'A',
            // KrheatmapList_all:[],
            KrheatmapListSido:[],
            KrheatmapList:[],
            // KrheatmapList_m: [],
            // KrheatmapList_j: [],
            YmdList:[],
            XList: [],
            XListSido: [],
            YListSido: [],


            YList: [],
            dataList: [],
            // blueList: ['#f2f7ff', '#e6f0ff', '#d9e8ff', '#cce0ff', '#bfd9ff', '#b2d1ff', '#a6c9ff', '#99c2ff', '#8cbaff', '#80b2ff', '#73abff', '#66a3ff', '#599cff', '#4d94ff', '#408cff', '#3385ff', '#267dff', '#1975ff', '#0d6eff', '#0066ff'],

            // blueList: ['#f2f2ff', '#e6e6ff', '#d9d9ff', '#ccccff', '#bfbfff', '#b2b2ff', '#a6a6ff', '#9999ff', '#8c8cff', '#8080ff', '#7373ff', '#6666ff', '#5959ff', '#4d4dff', '#4040ff', '#3333ff', '#2626ff', '#1919ff', '#0d0dff', '#0000ff'],
            // redList: ['#fff2f2', '#ffe6e6', '#ffd9d9', '#ffcccc', '#ffbfbf', '#ffb2b2', '#ffa6a6', '#ff9999', '#ff8c8c', '#ff8080', '#ff7373', '#ff6666', '#ff5959', '#ff4d4d', '#ff4040', '#ff3333', '#ff2626', '#ff1919', '#ff0d0d', '#ff0000'],

            blueList: ['#f2f2ff', '#e6e6ff', '#d9d9ff', '#ccccff', '#bfbfff', '#b2b2ff', '#a6a6ff', '#9999ff', '#8c8cff', '#8080ff', '#7373ff', '#6666ff', '#5959ff', '#4d4dff', '#4040ff', '#3333ff', '#0000b3', '#000099', '#000080', '#000066'],
            redList: ['#fff2f2', '#ffe6e6', '#ffd9d9', '#ffcccc', '#ffbfbf', '#ffb2b2', '#ffa6a6', '#ff9999', '#ff8c8c', '#ff8080', '#ff7373', '#ff6666', '#ff5959', '#ff4d4d', '#ff4040', '#ff3333', '#ff2626', '#ff1919', '#ff0d0d', '#ff0000'],

            // redList: ['#fcf2f2', '#fae6e6', '#f7d9d9', '#f5cccc', '#f2bfbf', '#f0b2b2', '#eda6a6', '#eb9999', '#e88c8c', '#e68080', '#e37373', '#e06666', '#de5959', '#db4d4d', '#d94040', '#d63333', '#d42626', '#d11919', '#cf0d0d', '#cc0000'],
            // provider:"",
            ma_jun: "매매",
            ymd: "2023-01-09",
            ji_code: "",
            ju: "",
            mon: "",
            year: "",
            firm: "kb",
            selected_firm: "kb",
            trade: "매매",
            date: "2023-01-30",
            selected_date: "2022-12-01",
            onePick : 0,
            num:0
                        
        };
    },
    methods: {
        minus() {
            if(this.num==this.YmdList.length-1){
                alert('맨처음 기준일자 입니다!!')
            } else {
                this.num++
                this.selected_date = this.YmdList[this.num].ymd
                this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date)
                this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date)      
            }
        },
        plus() {
            if(this.num==0){
                alert('가장 마지막 기준일자 입니다!!')
            } else {
                this.num--
                this.selected_date = this.YmdList[this.num].ymd
                this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date)
                this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date)    
            }
            
        },        
        // changeGubun(a) {
        //     if(a=='mon') {
        //         this.selected_date='2022-12-01'
        //         this.getKrheatmapListSido('api_series',this.selected_date);
        //         this.getKrheatmapList(this.selected_firm,this.selected_date); 
        //     }
        // },
        firstday() {
            this.num=this.date_list.length-1
            this.selected_date= this.YmdList[this.num].ymd
            this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);
            this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);            
        },                
        lastday() {
            this.num=0
            this.selected_date= this.YmdList[0].ymd
            this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);
            this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,this.selected_date);            
        },
        async getKrheatmapListSido(f,d) {

            let KrheatmapListSido = await this.$api("/api/KrheatmapListSido","post", { param: [f,d]});
            this.KrheatmapListSido=KrheatmapListSido

        },              
        async getKrheatmapList(f,d) {
            // let DataList=[]
            // let KrheatmapList_m=[]
            // let KrheatmapList_j=[]

            let KrheatmapList = await this.$api("/api/KrheatmapList","post", { param: [f,d]});
            this.KrheatmapList=KrheatmapList
            // let KrheatmapList_all=[]
            // for (let i = 0; i < KrheatmapList.length; i++) {
            //     KrheatmapList_all.push(KrheatmapList[i].m_dt)
            //     KrheatmapList_all.push(KrheatmapList[i].j_dt)

            // }
            // this.KrheatmapList_all=KrheatmapList_all


            // let KrheatmapList_m = cloneDeep(KrheatmapList);
            // let KrheatmapList_j = cloneDeep(KrheatmapList);



            // console.log(typeof(KrheatmapList))
            // console.log(KrheatmapList.length)

            // const KrheatmapList_m = [...KrheatmapList]

            // for (let i = 0; i < KrheatmapList_m.length; i++) {
            //     KrheatmapList_m[i].data= KrheatmapList_m[i].m_dt
            //     KrheatmapList_m[i].gubun= "m_dt"            

            //     delete KrheatmapList_m[i].m_dt;
            //     delete KrheatmapList_m[i].j_dt;
            //     delete KrheatmapList_m[i].m_js;
            //     delete KrheatmapList_m[i].j_js;

            //     // KrheatmapList_m.push(KrheatmapList[i])
            //     // KrheatmapList_j.push(KrheatmapList[i])
            // }

            // this.KrheatmapList_m=KrheatmapList_m
            // console.log('KrheatmapList_m',KrheatmapList_m)

            // for (let i = 0; i < KrheatmapList_j.length; i++) {
            //     KrheatmapList_j[i].data= KrheatmapList_j[i].j_dt
            //     KrheatmapList_j[i].gubun= "j_dt"            

            //     delete KrheatmapList_j[i].m_dt;
            //     delete KrheatmapList_j[i].j_dt;
            //     delete KrheatmapList_j[i].m_js;
            //     delete KrheatmapList_j[i].j_js;

            //     // KrheatmapList_m.push(KrheatmapList[i])
            //     // KrheatmapList_j.push(KrheatmapList[i])
            // }

            // this.KrheatmapList_j=KrheatmapList_j
            // let KrheatmapList_all=[...cloneDeep(KrheatmapList_m),...cloneDeep(KrheatmapList_j)]

            // // console.log('KrheatmapList_j',KrheatmapList_j)    
            // // console.log('KrheatmapList_all',KrheatmapList_all)   
            // this.KrheatmapList_all=KrheatmapList_all


        },        
        // async getKrheatmapList_m(f,d) {
        //     this.KrheatmapList_m = await this.$api("/api/KrheatmapList_m","post", { param: [f,d]});
        //     // console.log(this.getQuartile(-0.058914))
        // },
        // async getKrheatmapList_j(f,d) {
        //     this.KrheatmapList_j = await this.$api("/api/KrheatmapList_j","post", { param: [f,d]});
        //     // console.log(this.getQuartile(-0.058914))
        // }, 
        async getYmdList(f) {
            this.YmdList = await this.$api("/api/YmdList","post", {param: [f]});
            this.lastdate=this.YmdList[0].ymd

            let date_list = []
            for (let i = 0; i < this.YmdList.length; i++) {
                date_list.push(this.YmdList[i].ymd)
            }
            this.date_list = date_list
            // let last = this.YmdList[this.YmdList.length - 1];
            // this.selected_date=this.YmdList[0].ymd;
            // console.log(this.getQuartile(-0.058914))
        },               
        // getQuartile(n) {
        //     //console.log(this.KrheatmapList[0].x)
        //     let dataList = [];
        //     let dataListAbs = [];

        //     for (let i = 0; i < this.KrheatmapList.length; i++) {
        //         dataList.push(Number(this.KrheatmapList[i].data))
        //         dataListAbs.push(Math.abs(Number(this.KrheatmapList[i].data)))
        //     }
        //     let max = Math.max(...dataListAbs)
        //     // let max2 = Math.max(...dataList)
        //     // console.log(max)
        //     // console.log(max2)

        //     let quartile = Math.ceil(n * (20 / max))
        //     // console.log(n)
        //     // console.log(Math.ceil(0.1250331 * (20 / max)))

        //     if (n != 0) {
        //         return quartile                
        //     } else if (n == 0) {
        //         return 'white'
        //     } 
        // },
        // getColor(n) {
        //     if (n > 0) {
        //         return this.redList[n - 1]
        //     } else if (n < 0) {
        //         return this.blueList[Math.abs(n) - 1]
        //     } else {
        //         return 'white'
        //     }
        // },
        async getXListSido() {
            this.XListSido = await this.$api("/api/XListSido","post", {});
        }, 
        async getYListSido() {
            this.YListSido = await this.$api("/api/YListSido","post", {});
        },        
        async getXList() {
            this.XList = await this.$api("/api/XList","post", {});
        }, 
        async getYList() {
            this.YList = await this.$api("/api/YList","post", {});
        },
        goToPage() {
            this.$router.push({ path: '/krheatmap/' + this.$route.params.ymd });
        },
        //해당월 월요일만 가져오기 그후 계산해보기
        getWeekNumber(dateFrom = new Date()) {
            // 해당 날짜 (일)
            const currentDate = dateFrom.getDate();

            // 이번 달 1일로 지정
            const startOfMonth = new Date(dateFrom.setDate(1));

            // 이번 달 1일이 무슨 요일인지 확인
            const weekDay = startOfMonth.getDay(); // 0: Sun ~ 6: Sat

            //매달 시작일에 따른 빼줘야 하는 값
            const week_minus = new Array( 1, 0, 5, 4, 3, 2, 1 );

            // ((요일 - 1) + 해당 날짜) / 7일로 나누기 = N 주차
            return parseInt(((weekDay - week_minus[weekDay]) + currentDate) / 7) + 1;
        },
        changeGubun(a) {
            // this.$router.push({ path: '/krheatmap/' + a + '/' + this.date});
            this.num = 0
            if(a=='week') {
                this.getYmdList("api_series_"+this.selected_firm+"_"+a)
                // this.selected_date='2023-01-30'
                this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+a,this.selected_date)
                this.getKrheatmapList("api_series_"+this.selected_firm+"_"+a,this.selected_date)
                setTimeout(() => $("#mybtn").click(), 500);
            } else if(a=='mon'){
                this.getYmdList("api_series_"+this.selected_firm+"_"+a)
                // this.selected_date='2022-12-01'
                this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+a,this.selected_date)
                this.getKrheatmapList("api_series_"+this.selected_firm+"_"+a,this.selected_date)
                setTimeout(() => $("#mybtn").click(), 500);                
            }


            // this.getKrheatmapList_j(a,this.selected_date)

        },          
        changeFirm(a) {
            // this.$router.push({ path: '/krheatmap/' + a + '/' + this.date});
            this.getYmdList("api_series_"+a+"_"+this.selected_gubun)
            this.getKrheatmapListSido("api_series_"+a+"_"+this.selected_gubun,this.selected_date)
            this.getKrheatmapList("api_series_"+a+"_"+this.selected_gubun,this.selected_date)

            // this.getKrheatmapList_j(a,this.selected_date)

        },     
        changeDate(a) {
            // console.log(a)
            // this.$router.push({ path: '/krheatmap/' + this.firm  + '/' + a});
            this.getKrheatmapListSido("api_series_"+this.selected_firm+"_"+this.selected_gubun,a)
            this.getKrheatmapList("api_series_"+this.selected_firm+"_"+this.selected_gubun,a)
            // console.log(this.YmdList)
            let index_num= this.date_list.findIndex(e => e == a);
            // console.log(a,index_num)
            this.num = index_num
            // this.getKrheatmapList_j(this.selected_firm,a)            
        },
        provider() {
            if(this.selected_firm.includes('kb')) {
                return 'KB부동산'
            } else if(this.selected_firm.includes('r1')) {
                return '한국부동산원'
            }
        },
        gubun() {
            if(this.selected_gubun=='week') {
                return '주간'
            } else if(this.selected_gubun=='mon') {
                return '월간'
            }
        }        
        // showAlert() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'warning',
        //     title: '로그인이 필요합니다.',
        //     // showConfirmButton: false,
        //     // timer: 1500
        // });
        // // this.$Swal({
        // //   position: 'top-end',
        // //   icon: 'success',
        // //   title: 'Your work has been saved',
        // //   showConfirmButton: false,
        // //   timer: 1500
        // // });      
        // }, 

        // showAlert_login() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '로그인 되었습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },
        // showAlert_logout() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '로그아웃 되었습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },    
        // showAlert_NoUser() {
        // this.$swal.fire({
        //     // position: 'bottom-end',
        //     icon: 'success',
        //     title: '사용권한이 없습니다.',
        //     showConfirmButton: false,
        //     timer: 1500
        // });    
        // },                                                        

    }
}
</script>

<style scoped>
.one {
    /* background-color: beige; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.trade {
    /* background-color: beige; */
    display: flex;
}
.rent {
    /* background-color: beige; */
    display: flex;
}

/* 





table,th,td {
    border: 0px solid black;
    border-collapse: collapse;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    padding: 0px;
}

tr {
    height: 25px;
}

td {
    cursor: pointer;
    border-color: black;
    border-width: 1px;
    padding-left: 1px;
    padding-right: 1px;
    font-size: 10px;
}

.border-0 {
    border-width: 0px;
}

.t {
    border-color: black;
    border-top-width: 4px;
}

.b {
    border-color: black;
    border-bottom-width: 4px;
}

.l {
    border-color: black;
    border-left-width: 4px;
}

.r {
    border-color: black;
    border-right-width: 4px;
}

.a {
    min-width: 50px;
    border-color: black;
    border-width: 4px;
}
.scaled {
    transform:  scale(1);
    transform-origin: 0 0;
} */
</style>