<template>
        <div>
                <table id="tbl_deal" class="deal" style="float: left; margin-left: 10px; margin-right: 15px;">
                        <tbody>
                                <tr>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="all" on=""
                                                style="background-color: rgb(231, 178, 178); color: rgb(0, 0, 0);">서울
                                                <div id="서울"
                                                        style="background-color: rgb(231, 178, 178); color: black;">
                                                        -2.89</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(238, 197, 197); color: rgb(0, 0, 0);">경기
                                                <div id="경기"
                                                        style="background-color: rgb(238, 197, 197); color: black;">
                                                        -2.12</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(238, 197, 197); color: rgb(0, 0, 0);">인천
                                                <div id="인천"
                                                        style="background-color: rgb(238, 197, 197); color: black;">
                                                        -2.14</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(241, 209, 209); color: rgb(0, 0, 0);">세종
                                                <div id="세종"
                                                        style="background-color: rgb(241, 209, 209); color: black;">
                                                        -1.66</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(234, 185, 185); color: rgb(0, 0, 0);">대전
                                                <div id="대전"
                                                        style="background-color: rgb(234, 185, 185); color: black;">
                                                        -2.62</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(244, 216, 216); color: rgb(0, 0, 0);">대구
                                                <div id="대구"
                                                        style="background-color: rgb(244, 216, 216); color: black;">
                                                        -1.40</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(239, 203, 203); color: rgb(0, 0, 0);">부산
                                                <div id="부산"
                                                        style="background-color: rgb(239, 203, 203); color: black;">
                                                        -1.92</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(232, 180, 180); color: rgb(0, 0, 0);">울산
                                                <div id="울산"
                                                        style="background-color: rgb(232, 180, 180); color: black;">
                                                        -2.82</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(188, 187, 229); color: rgb(0, 0, 0);">광주
                                                <div id="광주"
                                                        style="background-color: rgb(188, 187, 229); color: black;">5.71
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(218, 218, 241); color: rgb(0, 0, 0);">제주
                                                <div id="제주"
                                                        style="background-color: rgb(218, 218, 241); color: black;">3.04
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(248, 248, 253); color: rgb(0, 0, 0);">충북
                                                <div id="충북"
                                                        style="background-color: rgb(248, 248, 253); color: black;">0.55
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(237, 194, 194); color: rgb(0, 0, 0);">충남
                                                <div id="충남"
                                                        style="background-color: rgb(237, 194, 194); color: black;">
                                                        -2.26</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(233, 233, 247); color: rgb(0, 0, 0);">강원
                                                <div id="강원"
                                                        style="background-color: rgb(233, 233, 247); color: black;">1.80
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">경남
                                                <div id="경남"
                                                        style="background-color: rgb(255, 255, 255); color: black;">0.01
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(243, 243, 251); color: rgb(0, 0, 0);">경북
                                                <div id="경북"
                                                        style="background-color: rgb(243, 243, 251); color: black;">0.98
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(231, 230, 246); color: rgb(0, 0, 0);">전남
                                                <div id="전남"
                                                        style="background-color: rgb(231, 230, 246); color: black;">1.99
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="all"
                                                style="background-color: rgb(251, 250, 253); color: rgb(0, 0, 0);">전북
                                                <div id="전북"
                                                        style="background-color: rgb(251, 250, 253); color: black;">0.36
                                                </div>
                                        </td>
                                </tr>
                        </tbody>
                </table>
                <table id="tbl_deal_gu" class="deal" style="float: left;">
                        <tbody>
                                <tr style="height: 10px;">
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="top left"
                                                style="background-color: rgb(235, 190, 190); color: rgb(0, 0, 0);">파주
                                                <div id="경기파주"
                                                        style="color: black; background-color: rgb(235, 190, 190);">
                                                        -2.41</div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(229, 170, 170); color: rgb(0, 0, 0);">양주
                                                <div id="경기양주"
                                                        style="color: black; background-color: rgb(229, 170, 170);">
                                                        -3.23</div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(216, 134, 134); color: rgb(0, 0, 0);">동두천
                                                <div id="경기동두천"
                                                        style="color: black; background-color: rgb(216, 134, 134);">
                                                        -4.77</div>
                                        </td>
                                        <td class="top left"
                                                style="background-color: rgb(226, 161, 161); color: rgb(0, 0, 0);">강북
                                                <div id="서울강북구"
                                                        style="color: black; background-color: rgb(226, 161, 161);">
                                                        -3.57</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(222, 151, 151); color: rgb(0, 0, 0);">도봉
                                                <div id="서울도봉구"
                                                        style="color: black; background-color: rgb(222, 151, 151);">
                                                        -4.01</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(242, 212, 212); color: rgb(0, 0, 0);">의정부
                                                <div id="경기의정부"
                                                        style="color: black; background-color: rgb(242, 212, 212);">
                                                        -1.58</div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="top left"
                                                style="background-color: rgb(251, 241, 241); color: rgb(0, 0, 0);">일산서
                                                <div id="경기고양일산서구"
                                                        style="color: black; background-color: rgb(251, 241, 241);">
                                                        -0.50</div>
                                        </td>
                                        <td style="background-color: rgb(241, 209, 209); color: rgb(0, 0, 0);">일산동<div
                                                        id="경기고양일산동구"
                                                        style="color: black; background-color: rgb(241, 209, 209);">
                                                        -1.68</div>
                                        </td>
                                        <td style="background-color: rgb(242, 210, 210); color: rgb(0, 0, 0);">고양덕양<div
                                                        id="경기고양덕양구"
                                                        style="color: black; background-color: rgb(242, 210, 210);">
                                                        -1.63</div>
                                        </td>
                                        <td class="top left"
                                                style="background-color: rgb(241, 209, 209); color: rgb(0, 0, 0);">은평
                                                <div id="서울은평구"
                                                        style="color: black; background-color: rgb(241, 209, 209);">
                                                        -1.68</div>
                                        </td>
                                        <td style="background-color: rgb(240, 205, 205); color: rgb(0, 0, 0);">성북<div
                                                        id="서울성북구"
                                                        style="color: black; background-color: rgb(240, 205, 205);">
                                                        -1.81</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(230, 174, 174); color: rgb(0, 0, 0);">노원
                                                <div id="서울노원구"
                                                        style="color: black; background-color: rgb(230, 174, 174);">
                                                        -3.06</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(245, 221, 221); color: rgb(0, 0, 0);">남양주
                                                <div id="경기남양주"
                                                        style="color: black; background-color: rgb(245, 221, 221);">
                                                        -1.23</div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(247, 226, 226); color: rgb(0, 0, 0);">김포
                                                <div id="경기김포"
                                                        style="color: black; background-color: rgb(247, 226, 226);">
                                                        -1.03</div>
                                        </td>
                                        <td style="background-color: rgb(244, 219, 219); color: rgb(0, 0, 0);">부천<div
                                                        id="경기부천"
                                                        style="color: black; background-color: rgb(244, 219, 219);">
                                                        -1.31</div>
                                        </td>
                                        <td class="top left"
                                                style="background-color: rgb(226, 163, 163); color: rgb(0, 0, 0);">강서
                                                <div id="서울강서구"
                                                        style="color: black; background-color: rgb(226, 163, 163);">
                                                        -3.51</div>
                                        </td>
                                        <td style="background-color: rgb(227, 165, 165); color: rgb(0, 0, 0);">서대문<div
                                                        id="서울서대문구"
                                                        style="color: black; background-color: rgb(227, 165, 165);">
                                                        -3.43</div>
                                        </td>
                                        <td style="background-color: rgb(203, 202, 235); color: rgb(0, 0, 0);">종로<div
                                                        id="서울종로구"
                                                        style="color: black; background-color: rgb(203, 202, 235);">4.38
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(240, 204, 204); color: rgb(0, 0, 0);">동대문<div
                                                        id="서울동대문구"
                                                        style="color: black; background-color: rgb(240, 204, 204);">
                                                        -1.86</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(253, 248, 248); color: rgb(0, 0, 0);">중랑
                                                <div id="서울중랑구"
                                                        style="color: black; background-color: rgb(253, 248, 248);">
                                                        -0.26</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(228, 228, 245); color: rgb(0, 0, 0);">구리
                                                <div id="경기구리"
                                                        style="color: black; background-color: rgb(228, 228, 245);">2.22
                                                </div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(243, 215, 215); color: rgb(0, 0, 0);">춘천
                                                <div id="강원춘천"
                                                        style="color: black; background-color: rgb(243, 215, 215);">
                                                        -1.45</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(244, 217, 217); color: rgb(0, 0, 0);">시흥
                                                <div id="경기시흥"
                                                        style="color: black; background-color: rgb(244, 217, 217);">
                                                        -1.38</div>
                                        </td>
                                        <td style="background-color: rgb(228, 167, 167); color: rgb(0, 0, 0);">광명<div
                                                        id="경기광명"
                                                        style="color: black; background-color: rgb(228, 167, 167);">
                                                        -3.34</div>
                                        </td>
                                        <td class="left"
                                                style="background-color: rgb(252, 245, 245); color: rgb(0, 0, 0);">양천
                                                <div id="서울양천구"
                                                        style="color: black; background-color: rgb(252, 245, 245);">
                                                        -0.35</div>
                                        </td>
                                        <td style="background-color: rgb(222, 149, 149); color: rgb(0, 0, 0);">마포<div
                                                        id="서울마포구"
                                                        style="color: black; background-color: rgb(222, 149, 149);">
                                                        -4.09</div>
                                        </td>
                                        <td style="background-color: rgb(228, 167, 167); color: rgb(0, 0, 0);">중구<div
                                                        id="서울중구"
                                                        style="color: black; background-color: rgb(228, 167, 167);">
                                                        -3.32</div>
                                        </td>
                                        <td style="background-color: rgb(239, 203, 203); color: rgb(0, 0, 0);">성동<div
                                                        id="서울성동구"
                                                        style="color: black; background-color: rgb(239, 203, 203);">
                                                        -1.91</div>
                                        </td>
                                        <td style="background-color: rgb(235, 188, 188); color: rgb(0, 0, 0);">강동<div
                                                        id="서울강동구"
                                                        style="color: black; background-color: rgb(235, 188, 188);">
                                                        -2.50</div>
                                        </td>
                                        <td class="left"
                                                style="background-color: rgb(237, 196, 196); color: rgb(0, 0, 0);">하남
                                                <div id="경기하남"
                                                        style="color: black; background-color: rgb(237, 196, 196);">
                                                        -2.19</div>
                                        </td>
                                        <td class="left right"
                                                style="background-color: rgb(178, 178, 225); color: rgb(0, 0, 0);">강릉
                                                <div id="강원강릉"
                                                        style="color: black; background-color: rgb(178, 178, 225);">6.60
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="top left"
                                                style="background-color: rgb(245, 220, 220); color: rgb(0, 0, 0);">인천서
                                                <div id="인천서구"
                                                        style="color: black; background-color: rgb(245, 220, 220);">
                                                        -1.28</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(224, 155, 155); color: rgb(0, 0, 0);">인천동
                                                <div id="인천동구"
                                                        style="color: black; background-color: rgb(224, 155, 155);">
                                                        -3.85</div>
                                        </td>
                                        <td class="top bottom"
                                                style="background-color: rgb(221, 147, 147); color: rgb(0, 0, 0);">구로
                                                <div id="서울구로구"
                                                        style="color: black; background-color: rgb(221, 147, 147);">
                                                        -4.18</div>
                                        </td>
                                        <td style="background-color: rgb(213, 126, 126); color: rgb(0, 0, 0);">영등포<div
                                                        id="서울영등포구"
                                                        style="color: black; background-color: rgb(213, 126, 126);">
                                                        -5.10</div>
                                        </td>
                                        <td style="background-color: rgb(248, 230, 230); color: rgb(0, 0, 0);">동작<div
                                                        id="서울동작구"
                                                        style="color: black; background-color: rgb(248, 230, 230);">
                                                        -0.88</div>
                                        </td>
                                        <td style="background-color: rgb(254, 252, 252); color: rgb(0, 0, 0);">용산<div
                                                        id="서울용산구"
                                                        style="color: black; background-color: rgb(254, 252, 252);">
                                                        -0.10</div>
                                        </td>
                                        <td style="background-color: rgb(224, 156, 156); color: rgb(0, 0, 0);">광진<div
                                                        id="서울광진구"
                                                        style="color: black; background-color: rgb(224, 156, 156);">
                                                        -3.78</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(216, 133, 133); color: rgb(0, 0, 0);">송파
                                                <div id="서울송파구"
                                                        style="color: black; background-color: rgb(216, 133, 133);">
                                                        -4.80</div>
                                        </td>
                                        <td style="background-color: rgb(235, 189, 189); color: rgb(0, 0, 0);">광주<div
                                                        id="경기광주"
                                                        style="color: black; background-color: rgb(235, 189, 189);">
                                                        -2.44</div>
                                        </td>
                                        <td class="left bottom right"
                                                style="background-color: rgb(223, 222, 243); color: rgb(0, 0, 0);">원주
                                                <div id="강원원주"
                                                        style="color: black; background-color: rgb(223, 222, 243);">2.66
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left"
                                                style="background-color: rgb(210, 119, 119); color: rgb(0, 0, 0);">인천중
                                                <div id="인천중구"
                                                        style="color: black; background-color: rgb(210, 119, 119);">
                                                        -5.46</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(231, 177, 177); color: rgb(0, 0, 0);">인천계양
                                                <div id="인천계양구"
                                                        style="color: black; background-color: rgb(231, 177, 177);">
                                                        -2.94</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(212, 123, 123); color: rgb(0, 0, 0);">군포
                                                <div id="경기군포"
                                                        style="color: black; background-color: rgb(212, 123, 123);">
                                                        -5.27</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(209, 115, 115); color: rgb(0, 0, 0);">금천
                                                <div id="서울금천구"
                                                        style="color: black; background-color: rgb(209, 115, 115);">
                                                        -5.62</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(231, 176, 176); color: rgb(0, 0, 0);">관악
                                                <div id="서울관악구"
                                                        style="color: black; background-color: rgb(231, 176, 176);">
                                                        -2.96</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(218, 140, 140); color: rgb(0, 0, 0);">서초
                                                <div id="서울서초구"
                                                        style="color: black; background-color: rgb(218, 140, 140);">
                                                        -4.48</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(238, 197, 197); color: rgb(0, 0, 0);">강남
                                                <div id="서울강남구"
                                                        style="color: black; background-color: rgb(238, 197, 197);">
                                                        -2.14</div>
                                        </td>
                                        <td style="background-color: rgb(217, 137, 137); color: rgb(0, 0, 0);">성남수정<div
                                                        id="경기성남수정구"
                                                        style="color: black; background-color: rgb(217, 137, 137);">
                                                        -4.61</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(222, 150, 150); color: rgb(0, 0, 0);">용인수지
                                                <div id="경기용인수지구"
                                                        style="color: black; background-color: rgb(222, 150, 150);">
                                                        -4.07</div>
                                        </td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="left"
                                                style="background-color: rgb(241, 207, 207); color: rgb(0, 0, 0);">미추홀
                                                <div id="인천미추홀구"
                                                        style="color: black; background-color: rgb(241, 207, 207);">
                                                        -1.74</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(239, 202, 202); color: rgb(0, 0, 0);">인천부평
                                                <div id="인천부평구"
                                                        style="color: black; background-color: rgb(239, 202, 202);">
                                                        -1.96</div>
                                        </td>
                                        <td style="background-color: rgb(230, 172, 172); color: rgb(0, 0, 0);">안산상록<div
                                                        id="경기안산상록구"
                                                        style="color: black; background-color: rgb(230, 172, 172);">
                                                        -3.11</div>
                                        </td>
                                        <td style="background-color: rgb(247, 226, 226); color: rgb(0, 0, 0);">안양만안<div
                                                        id="경기안양만안구"
                                                        style="color: black; background-color: rgb(247, 226, 226);">
                                                        -1.04</div>
                                        </td>
                                        <td style="background-color: rgb(236, 193, 193); color: rgb(0, 0, 0);">안양동안<div
                                                        id="경기안양동안구"
                                                        style="color: black; background-color: rgb(236, 193, 193);">
                                                        -2.30</div>
                                        </td>
                                        <td style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">과천<div
                                                        id="경기과천"
                                                        style="color: black; background-color: rgb(255, 255, 255);">0.00
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(230, 173, 173); color: rgb(0, 0, 0);">분당<div
                                                        id="경기성남분당구"
                                                        style="color: black; background-color: rgb(230, 173, 173);">
                                                        -3.10</div>
                                        </td>
                                        <td style="background-color: rgb(249, 235, 235); color: rgb(0, 0, 0);">성남중원<div
                                                        id="경기성남중원구"
                                                        style="color: black; background-color: rgb(249, 235, 235);">
                                                        -0.70</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(231, 176, 176); color: rgb(0, 0, 0);">용인기흥
                                                <div id="경기용인기흥구"
                                                        style="color: black; background-color: rgb(231, 176, 176);">
                                                        -2.98</div>
                                        </td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="left bottom"
                                                style="background-color: rgb(234, 186, 186); color: rgb(0, 0, 0);">인천연수
                                                <div id="인천연수구"
                                                        style="color: black; background-color: rgb(234, 186, 186);">
                                                        -2.55</div>
                                        </td>
                                        <td class="right bottom"
                                                style="background-color: rgb(243, 215, 215); color: rgb(0, 0, 0);">인천남동
                                                <div id="인천남동구"
                                                        style="color: black; background-color: rgb(243, 215, 215);">
                                                        -1.44</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(243, 216, 216); color: rgb(0, 0, 0);">안산단원
                                                <div id="경기안산단원구"
                                                        style="color: black; background-color: rgb(243, 216, 216);">
                                                        -1.41</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(235, 187, 187); color: rgb(0, 0, 0);">화성
                                                <div id="경기화성"
                                                        style="color: black; background-color: rgb(235, 187, 187);">
                                                        -2.51</div>
                                        </td>
                                        <td style="background-color: rgb(250, 250, 253); color: rgb(0, 0, 0);">오산<div
                                                        id="경기오산"
                                                        style="color: black; background-color: rgb(250, 250, 253);">0.42
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(243, 215, 215); color: rgb(0, 0, 0);">의왕<div
                                                        id="경기의왕"
                                                        style="color: black; background-color: rgb(243, 215, 215);">
                                                        -1.43</div>
                                        </td>
                                        <td style="background-color: rgb(197, 86, 86); color: rgb(0, 0, 0);">수원장안<div
                                                        id="경기수원장안구"
                                                        style="color: black; background-color: rgb(197, 86, 86);">-7.08
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(224, 154, 154); color: rgb(0, 0, 0);">수원영통<div
                                                        id="경기수원영통구"
                                                        style="color: black; background-color: rgb(224, 154, 154);">
                                                        -3.86</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(238, 197, 197); color: rgb(0, 0, 0);">용인처인
                                                <div id="경기용인처인구"
                                                        style="color: black; background-color: rgb(238, 197, 197);">
                                                        -2.14</div>
                                        </td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left bottom"
                                                style="background-color: rgb(245, 220, 220); color: rgb(0, 0, 0);">평택
                                                <div id="경기평택"
                                                        style="color: black; background-color: rgb(245, 220, 220);">
                                                        -1.26</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(244, 244, 251); color: rgb(0, 0, 0);">안성
                                                <div id="경기안성"
                                                        style="color: black; background-color: rgb(244, 244, 251);">0.85
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(219, 140, 140); color: rgb(0, 0, 0);">수원권선
                                                <div id="경기수원권선구"
                                                        style="color: black; background-color: rgb(219, 140, 140);">
                                                        -4.47</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(254, 250, 250); color: rgb(0, 0, 0);">수원팔달
                                                <div id="경기수원팔달구"
                                                        style="color: black; background-color: rgb(254, 250, 250);">
                                                        -0.18</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(230, 230, 246); color: rgb(0, 0, 0);">이천
                                                <div id="경기이천"
                                                        style="color: black; background-color: rgb(230, 230, 246);">2.01
                                                </div>
                                        </td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr style="height: 10px;">
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                </tr>
                                <tr>
                                        <td class="left top"
                                                style="background-color: rgb(235, 189, 189); color: rgb(0, 0, 0);">천안서북
                                                <div id="충남천안서북구"
                                                        style="color: black; background-color: rgb(235, 189, 189);">
                                                        -2.44</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(231, 178, 178); color: rgb(0, 0, 0);">천안동남
                                                <div id="충남천안동남구"
                                                        style="color: black; background-color: rgb(231, 178, 178);">
                                                        -2.88</div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(241, 209, 209); color: rgb(0, 0, 0);">세종
                                                <div id="세종"
                                                        style="background-color: rgb(241, 209, 209); color: black;">
                                                        -1.66</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(185, 58, 58); color: rgb(255, 255, 255);">
                                                대전대덕
                                                <div id="대전대덕구"
                                                        style="color: white; background-color: rgb(185, 58, 58);">-8.60
                                                </div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(228, 167, 167); color: rgb(0, 0, 0);">충주
                                                <div id="충북충주"
                                                        style="color: black; background-color: rgb(228, 167, 167);">
                                                        -3.32</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(122, 121, 200); color: rgb(255, 255, 255);">
                                                제천
                                                <div id="충북제천"
                                                        style="color: white; background-color: rgb(122, 121, 200);">
                                                        12.32</div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="top left bottom"
                                                style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">김천
                                                <div id="경북김천"
                                                        style="color: black; background-color: rgb(255, 255, 255);">0.00
                                                </div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(244, 243, 251); color: rgb(0, 0, 0);">구미
                                                <div id="경북구미"
                                                        style="color: black; background-color: rgb(244, 243, 251);">0.92
                                                </div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(220, 146, 146); color: rgb(0, 0, 0);">경주
                                                <div id="경북경주"
                                                        style="color: black; background-color: rgb(220, 146, 146);">
                                                        -4.24</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left"
                                                style="background-color: rgb(242, 242, 250); color: rgb(0, 0, 0);">아산
                                                <div id="충남아산"
                                                        style="color: black; background-color: rgb(242, 242, 250);">1.05
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(190, 70, 70); color: rgb(0, 0, 0);">당진<div
                                                        id="충남당진"
                                                        style="color: black; background-color: rgb(190, 70, 70);">-7.92
                                                </div>
                                        </td>
                                        <td class="left"
                                                style="background-color: rgb(252, 252, 254); color: rgb(0, 0, 0);">대전유성
                                                <div id="대전유성구"
                                                        style="color: black; background-color: rgb(252, 252, 254);">0.24
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(254, 254, 255); color: rgb(0, 0, 0);">대전중구
                                                <div id="대전중구"
                                                        style="color: black; background-color: rgb(254, 254, 255);">0.06
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(251, 251, 254); color: rgb(0, 0, 0);">청주청원<div
                                                        id="충북청주청원구"
                                                        style="color: black; background-color: rgb(251, 251, 254);">0.29
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(234, 234, 247); color: rgb(0, 0, 0);">청주상당
                                                <div id="충북청주상당구"
                                                        style="color: black; background-color: rgb(234, 234, 247);">1.70
                                                </div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(110, 109, 194); color: rgb(255, 255, 255);">
                                                안동<div id="경북안동"
                                                        style="color: white; background-color: rgb(110, 109, 194);">
                                                        13.66</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(253, 249, 249); color: rgb(0, 0, 0);">포항북구
                                                <div id="경북포항북구"
                                                        style="color: black; background-color: rgb(253, 249, 249);">
                                                        -0.20</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left"
                                                style="background-color: rgb(242, 242, 250); color: rgb(0, 0, 0);">서산
                                                <div id="충남서산"
                                                        style="color: black; background-color: rgb(242, 242, 250);">1.06
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(204, 103, 103); color: rgb(0, 0, 0);">공주<div
                                                        id="충남공주"
                                                        style="color: black; background-color: rgb(204, 103, 103);">
                                                        -6.19</div>
                                        </td>
                                        <td class="left bottom"
                                                style="background-color: rgb(220, 144, 144); color: rgb(0, 0, 0);">대전서구
                                                <div id="대전서구"
                                                        style="color: black; background-color: rgb(220, 144, 144);">
                                                        -4.32</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(222, 151, 151); color: rgb(0, 0, 0);">대전동구
                                                <div id="대전동구"
                                                        style="color: black; background-color: rgb(222, 151, 151);">
                                                        -4.03</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(254, 251, 251); color: rgb(0, 0, 0);">청주흥덕
                                                <div id="충북청주흥덕구"
                                                        style="color: black; background-color: rgb(254, 251, 251);">
                                                        -0.13</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(252, 252, 254); color: rgb(0, 0, 0);">청주서원
                                                <div id="충북청주서원구"
                                                        style="color: black; background-color: rgb(252, 252, 254);">0.20
                                                </div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(249, 233, 233); color: rgb(0, 0, 0);">대구북구
                                                <div id="대구북구"
                                                        style="color: black; background-color: rgb(249, 233, 233);">
                                                        -0.78</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(246, 225, 225); color: rgb(0, 0, 0);">대구동구
                                                <div id="대구동구"
                                                        style="color: black; background-color: rgb(246, 225, 225);">
                                                        -1.07</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(219, 219, 242); color: rgb(0, 0, 0);">경산
                                                <div id="경북경산"
                                                        style="color: black; background-color: rgb(219, 219, 242);">2.97
                                                </div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(234, 234, 247); color: rgb(0, 0, 0);">포항남구
                                                <div id="경북포항남구"
                                                        style="color: black; background-color: rgb(234, 234, 247);">1.70
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="bottom left"
                                                style="background-color: rgb(124, 123, 201); color: rgb(255, 255, 255);">
                                                논산
                                                <div id="충남논산"
                                                        style="color: white; background-color: rgb(124, 123, 201);">
                                                        12.07</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(153, 0, 0); color: rgb(255, 255, 255);">계룡
                                                <div id="충남계룡" style="color: white; background-color: rgb(153, 0, 0);">
                                                        -12.50</div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(241, 241, 250); color: rgb(0, 0, 0);">대구서구
                                                <div id="대구서구"
                                                        style="color: black; background-color: rgb(241, 241, 250);">1.14
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(251, 241, 241); color: rgb(0, 0, 0);">대구수성
                                                <div id="대구수성구"
                                                        style="color: black; background-color: rgb(251, 241, 241);">
                                                        -0.50</div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(226, 162, 162); color: rgb(0, 0, 0);">울산울주군
                                                <div id="울산울주군"
                                                        style="color: black; background-color: rgb(226, 162, 162);">
                                                        -3.54</div>
                                        </td>
                                        <td class="top right"
                                                style="background-color: rgb(238, 198, 198); color: rgb(0, 0, 0);">울산북구
                                                <div id="울산북구"
                                                        style="color: black; background-color: rgb(238, 198, 198);">
                                                        -2.08</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left top bottom"
                                                style="background-color: rgb(253, 248, 248); color: rgb(0, 0, 0);">군산
                                                <div id="전북군산"
                                                        style="color: black; background-color: rgb(253, 248, 248);">
                                                        -0.26</div>
                                        </td>
                                        <td class="top bottom"
                                                style="background-color: rgb(251, 251, 254); color: rgb(0, 0, 0);">익산
                                                <div id="전북익산"
                                                        style="color: black; background-color: rgb(251, 251, 254);">0.31
                                                </div>
                                        </td>
                                        <td class="top bottom"
                                                style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">전주덕진
                                                <div id="전북전주덕진구"
                                                        style="color: black; background-color: rgb(255, 255, 255);">0.00
                                                </div>
                                        </td>
                                        <td class="top bottom right"
                                                style="background-color: rgb(241, 241, 250); color: rgb(0, 0, 0);">전주완산
                                                <div id="전북전주완산구"
                                                        style="color: black; background-color: rgb(241, 241, 250);">1.12
                                                </div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(243, 214, 214); color: rgb(0, 0, 0);">달서구
                                                <div id="대구달서구"
                                                        style="color: black; background-color: rgb(243, 214, 214);">
                                                        -1.50</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(248, 231, 231); color: rgb(0, 0, 0);">대구중구
                                                <div id="대구중구"
                                                        style="color: black; background-color: rgb(248, 231, 231);">
                                                        -0.84</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(232, 179, 179); color: rgb(0, 0, 0);">울산중구
                                                <div id="울산중구"
                                                        style="color: black; background-color: rgb(232, 179, 179);">
                                                        -2.86</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(220, 144, 144); color: rgb(0, 0, 0);">울산남구
                                                <div id="울산남구"
                                                        style="color: black; background-color: rgb(220, 144, 144);">
                                                        -4.31</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left top"
                                                style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">김해
                                                <div id="경남김해"
                                                        style="color: black; background-color: rgb(255, 255, 255);">0.01
                                                </div>
                                        </td>
                                        <td class="right top"
                                                style="background-color: rgb(247, 247, 252); color: rgb(0, 0, 0);">양산
                                                <div id="경남양산"
                                                        style="color: black; background-color: rgb(247, 247, 252);">0.66
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(219, 143, 143); color: rgb(0, 0, 0);">대구달성군
                                                <div id="대구달성군"
                                                        style="color: black; background-color: rgb(219, 143, 143);">
                                                        -4.36</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(233, 184, 184); color: rgb(0, 0, 0);">대구남구
                                                <div id="대구남구"
                                                        style="color: black; background-color: rgb(233, 184, 184);">
                                                        -2.66</div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="right left bottom"
                                                style="background-color: rgb(252, 252, 254); color: rgb(0, 0, 0);">
                                                울산동구<div id="울산동구"
                                                        style="color: black; background-color: rgb(252, 252, 254);">0.20
                                                </div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left top"
                                                style="background-color: rgb(158, 157, 217); color: rgb(0, 0, 0);">광주광산
                                                <div id="광주광산구"
                                                        style="color: black; background-color: rgb(158, 157, 217);">8.55
                                                </div>
                                        </td>
                                        <td class="right top"
                                                style="background-color: rgb(188, 187, 229); color: rgb(0, 0, 0);">광주북
                                                <div id="광주북구"
                                                        style="color: black; background-color: rgb(188, 187, 229);">5.73
                                                </div>
                                        </td>
                                        <td class="left top"
                                                style="background-color: rgb(234, 234, 247); color: rgb(0, 0, 0);">순천
                                                <div id="전남순천"
                                                        style="color: black; background-color: rgb(234, 234, 247);">1.68
                                                </div>
                                        </td>
                                        <td class="right top"
                                                style="background-color: rgb(174, 36, 36); color: rgb(255, 255, 255);">
                                                광양<div id="전남광양"
                                                        style="color: white; background-color: rgb(174, 36, 36);">-9.89
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(234, 234, 247); color: rgb(0, 0, 0);">진주<div
                                                        id="경남진주"
                                                        style="color: black; background-color: rgb(234, 234, 247);">1.68
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(248, 248, 253); color: rgb(0, 0, 0);">창원의창구
                                                <div id="경남창원의창구"
                                                        style="color: black; background-color: rgb(248, 248, 253);">0.55
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(231, 177, 177); color: rgb(0, 0, 0);">부산북<div
                                                        id="부산북구"
                                                        style="color: black; background-color: rgb(231, 177, 177);">
                                                        -2.92</div>
                                        </td>
                                        <td style="background-color: rgb(232, 178, 178); color: rgb(0, 0, 0);">부산진구<div
                                                        id="부산부산진구"
                                                        style="color: black; background-color: rgb(232, 178, 178);">
                                                        -2.87</div>
                                        </td>
                                        <td class="top"
                                                style="background-color: rgb(249, 248, 253); color: rgb(0, 0, 0);">금정구
                                                <div id="부산금정구"
                                                        style="color: black; background-color: rgb(249, 248, 253);">0.52
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(250, 238, 238); color: rgb(0, 0, 0);">기장군
                                                <div id="부산기장군"
                                                        style="color: black; background-color: rgb(250, 238, 238);">
                                                        -0.62</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="left"
                                                style="background-color: rgb(225, 224, 244); color: rgb(0, 0, 0);">광주서
                                                <div id="광주서구"
                                                        style="color: black; background-color: rgb(225, 224, 244);">2.49
                                                </div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(231, 230, 246); color: rgb(0, 0, 0);">광주동
                                                <div id="광주동구"
                                                        style="color: black; background-color: rgb(231, 230, 246);">2.00
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(140, 139, 209); color: rgb(255, 255, 255);">
                                                목포<div id="전남목포"
                                                        style="color: white; background-color: rgb(140, 139, 209);">
                                                        10.35</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(228, 228, 245); color: rgb(0, 0, 0);">여수
                                                <div id="전남여수"
                                                        style="color: black; background-color: rgb(228, 228, 245);">2.21
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(216, 215, 240); color: rgb(0, 0, 0);">마산회원<div
                                                        id="경남창원마산회원구"
                                                        style="color: black; background-color: rgb(216, 215, 240);">3.25
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(223, 153, 153); color: rgb(0, 0, 0);">창원성산<div
                                                        id="경남창원성산구"
                                                        style="color: black; background-color: rgb(223, 153, 153);">
                                                        -3.94</div>
                                        </td>
                                        <td class="left"
                                                style="background-color: rgb(250, 237, 237); color: rgb(0, 0, 0);">부산강서
                                                <div id="부산강서구"
                                                        style="color: black; background-color: rgb(250, 237, 237);">
                                                        -0.63</div>
                                        </td>
                                        <td style="background-color: rgb(245, 220, 220); color: rgb(0, 0, 0);">부산서<div
                                                        id="부산서구"
                                                        style="color: black; background-color: rgb(245, 220, 220);">
                                                        -1.27</div>
                                        </td>
                                        <td style="background-color: rgb(228, 169, 169); color: rgb(0, 0, 0);">동래구<div
                                                        id="부산동래구"
                                                        style="color: black; background-color: rgb(228, 169, 169);">
                                                        -3.25</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(235, 188, 188); color: rgb(0, 0, 0);">해운대
                                                <div id="부산해운대구"
                                                        style="color: black; background-color: rgb(235, 188, 188);">
                                                        -2.48</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td class="bottom left right"
                                                style="background-color: rgb(167, 166, 220); color: rgb(0, 0, 0);">광주남
                                                <div id="광주남구"
                                                        style="color: black; background-color: rgb(167, 166, 220);">7.72
                                                </div>
                                        </td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="noborder"></td>
                                        <td class="left"
                                                style="background-color: rgb(200, 200, 234); color: rgb(0, 0, 0);">마산합포
                                                <div id="경남창원마산합포구"
                                                        style="color: black; background-color: rgb(200, 200, 234);">4.59
                                                </div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(242, 242, 250); color: rgb(0, 0, 0);">창원진해
                                                <div id="경남창원진해구"
                                                        style="color: black; background-color: rgb(242, 242, 250);">1.01
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(245, 222, 222); color: rgb(0, 0, 0);">사상<div
                                                        id="부산사상구"
                                                        style="color: black; background-color: rgb(245, 222, 222);">
                                                        -1.20</div>
                                        </td>
                                        <td style="background-color: rgb(200, 91, 91); color: rgb(0, 0, 0);">부산동<div
                                                        id="부산동구"
                                                        style="color: black; background-color: rgb(200, 91, 91);">-6.78
                                                </div>
                                        </td>
                                        <td style="background-color: rgb(234, 185, 185); color: rgb(0, 0, 0);">부산연제<div
                                                        id="부산연제구"
                                                        style="color: black; background-color: rgb(234, 185, 185);">
                                                        -2.62</div>
                                        </td>
                                        <td class="right"
                                                style="background-color: rgb(245, 222, 222); color: rgb(0, 0, 0);">부산수영
                                                <div id="부산수영구"
                                                        style="color: black; background-color: rgb(245, 222, 222);">
                                                        -1.20</div>
                                        </td>
                                </tr>
                                <tr>
                                        <td colspan="2" class="noborder">
                                        </td>
                                        <td class="left top bottom"
                                                style="background-color: rgb(212, 212, 239); color: rgb(0, 0, 0);">제주시
                                                <div id="제주제주"
                                                        style="color: black; background-color: rgb(212, 212, 239);">3.57
                                                </div>
                                        </td>
                                        <td class="bottom top right"
                                                style="background-color: rgb(227, 226, 245); color: rgb(0, 0, 0);">서귀포
                                                <div id="제주서귀포"
                                                        style="color: black; background-color: rgb(227, 226, 245);">2.33
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(163, 162, 219); color: rgb(0, 0, 0);">통영
                                                <div id="경남통영"
                                                        style="color: black; background-color: rgb(163, 162, 219);">8.12
                                                </div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(196, 82, 82); color: rgb(0, 0, 0);">거제<div
                                                        id="경남거제"
                                                        style="color: black; background-color: rgb(196, 82, 82);">-7.24
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(251, 241, 241); color: rgb(0, 0, 0);">부산사하
                                                <div id="부산사하구"
                                                        style="color: black; background-color: rgb(251, 241, 241);">
                                                        -0.51</div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(49, 47, 157); color: rgb(255, 255, 255);">
                                                부산중<div id="부산중구"
                                                        style="color: white; background-color: rgb(49, 47, 157);">21.82
                                                </div>
                                        </td>
                                        <td class="bottom"
                                                style="background-color: rgb(223, 153, 153); color: rgb(0, 0, 0);">부산영도
                                                <div id="부산영도구"
                                                        style="color: black; background-color: rgb(223, 153, 153);">
                                                        -3.92</div>
                                        </td>
                                        <td class="bottom right"
                                                style="background-color: rgb(242, 210, 210); color: rgb(0, 0, 0);">부산남
                                                <div id="부산남구"
                                                        style="color: black; background-color: rgb(242, 210, 210);">
                                                        -1.65</div>
                                        </td>
                                </tr>
                        </tbody>
                </table>
        </div>

</template>


<style scoped>

        html, body, .chart, svg {     
            margin: 0px;
            padding: 0px;
            width: 100%;
            height: 100%;
        }
        
        .stitle {
            font-family: 'ROKAFSlabSerifBold' !important;  
            font-size: 18px;       
            font-weight: bold;
        }
        
        svg {
            display: block;
        }
        
        .chart {
            position: fixed;
            visibility: hidden; 
            height: 450px;                                   
            width: 400px;                                                                
            z-index: 100;
            background-color: white;  
            border: solid;
            border-width: 5px;   
            border-radius: 10px; 
            padding: 10px;       
        }        
        
        .content {  
            min-height: 250px;
            padding: 0px;   
            margin-right: auto;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 17px; 
        }
        .box-header {
            color: #444;
            display: block;
            padding: 5px;  
            position: relative;  
        }
        
        .form-control {
            padding: 3px 3px;   
            font-weight: bold; 
            height: 30px;     
        }
        table, th, td {
            border: 0px solid black;
            border-collapse: collapse;
            font-size: 13px;     
            font-weight: bold;  
            text-align: center;
            padding : 0px;
        }
        tr {
            height: 25px;   
        }
        
        td {
            cursor: pointer; 
            border-color: black;     
            border-width: 1px;    
            padding-left: 1px;
            padding-right: 1px;
            font-size:12px;   
        }
        
        .noborder {
            border-width: 0px;
        }
        
        .top {
            border-color: black;
            border-top-width: 4px;
        }
        .bottom {
            border-color: black;
            border-bottom-width: 4px;
        }
        .left {
            border-color: black;
            border-left-width: 4px;
        }
        .right {
            border-color: black;
            border-right-width: 4px;
        }
        .all {
            min-width: 50px;  
            border-color: black;
            border-width: 4px; 
        }
        body {
            line-height: 1.15;   /*테이블 줄 간격 */   
        }
        
        .btn {
            padding-top: 3px;
            padding-bottom: 3px;           
        }
        
        
        .box-header {
            color: #444;
            display: block;
            padding-top: 3px;  
            padding-bottom: 0px;    
            position: relative;
        }
        
        .form-group {
            margin-bottom: 0px;
        }
        #moveDeal {
            display: none; /* Hidden by default */
            position: fixed; /* Fixed/sticky position */
            bottom: 38px; /* Place the button at the bottom of the page */ 
            right: 30px; /* Place the button 30px from the right */
            z-index: 20; /* Make sure it does not overlap */ 
            border: none; /* Remove borders */
            outline: none; /* Remove outline */
            background-color: #1f77b4; /* Set a background color */ 
            color: white; /* Text color */
            cursor: pointer; /* Add a mouse pointer on hover */
            padding: 5px; /* Some padding */
            border-radius: 6px; /* Rounded corners */ 
            font-size: 14px; /* Increase font size */  
            font-weight: bold;
        }
            
        #moveDeal:hover { 
            background-color: #555; /* Add a dark-grey background on hover */
        }
        
        #moveLease {
            display: block; /* Hidden by default */
            position: fixed; /* Fixed/sticky position */
            bottom: 10px; /* Place the button at the bottom of the page */
            right: 30px; /* Place the button 30px from the right */
            z-index: 20; /* Make sure it does not overlap */ 
            border: none; /* Remove borders */
            outline: none; /* Remove outline */
            background-color: #d62728; /* Set a background color */ 
            color: white; /* Text color */
            cursor: pointer; /* Add a mouse pointer on hover */
            padding: 5px; /* Some padding */
            border-radius: 6px; /* Rounded corners */
            font-size: 14px; /* Increase font size */ 
            font-weight: bold; 
        }
            
        #moveLease:hover {
            background-color: #555; /* Add a dark-grey background on hover */
        }
        
        /* 아이콘 부분 */  
        .fa { 
            font-size: 20px;             
            cursor: pointer;  
            user-select: none; 
            color: #5755AF;    
            margin-top: 5px;          
            vertical-align: top;  		    
        }
        
        .fa:hover {
            color: red;     
        }
        
        label {
            display: inline-block;
            max-width: 100%;   
            padding-top: 1px;   
            padding-bottom: 0px;  
            font-weight: 500;  
            vertical-align: text-top;  
        }
        
        /* 구글차트 툴팁 */   
        div.google-visualization-tooltip {
            width: 190px !important;    
        }  
        div.google-visualization-tooltip > ul > li > span { 
            font-size: 13px !important; 
            font-weight: bold !important;   
        } 
        
        #ex1Slider .slider-selection {
            background: #BABABA;
        }
        #ex2Slider .slider-selection {
            background: #BABABA;
        }
        
        .slider.slider-horizontal {
            width: 310px; 
            height: 20px;
        }
</style>