<template>
    <main class="mt-1">
        <!-- <div>갯수:{{ AgentList.length }}</div> -->

        <div v-if="isLoading" class="loading-container">
            <div class="loading">
                <Fade-loader />
            </div>
        </div>

        <div class="container-fluid">

            <div class="row mb-1">
                <div class="col-12">
                    <div class="row">
            
            
                        <div class="input-group">

                            <div class="col-auto">
                                <select class="form-select" v-model="sido" @change="changeSido(sido);">
                                    <option :value="a.cortarName" :key=i v-for="(a,i) in SidoList">
                                        {{a.cortarName}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-auto">
                            <select class="form-select" v-model="sigun" @change="changeSigun(sigun);">
                                <option :value="a['sigun']" :key=i v-for="(a, i) in SigunList1">
                                    {{a.sigun }}
                                </option>
                            </select>
                            </div>

                            <button class="btn btn-primary" @click="TelNumCopy" type="button" v-clipboard:copy="selected"
                                v-clipboard:success="onCopy" v-clipboard:error="onError">
                                선택한 전화번호 복사하기 <span class="badge bg-secondary">{{selected.length}}</span>
                            </button>

                        </div>

            
                    </div>
                </div>
            </div>

                <vue-good-table v-on:selected-rows-change="selectionChanged" :columns="columns" :rows="AgentList"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 15,
                        perPageDropdown: [15],
                        dropdownAllowAll: false,
                    }"
                    :select-options="{ enabled: true, selectionText: '소장님 선택', clearSelectionText: '모두체크해제'}"
                    :search-options="{ enabled: true, externalQuery: searchQuery, placeholder: '소재지,부동산사무소,대표자,전화 또는 mobile을 검색해 보세요.' }" 
                    styleClass="vgt-table striped">

                    <!-- <template #table-row="props">
                        <div> 
                            <span class="fw-bold">소재지</span> {{ props.row.address }}
                            <span class="fw-bold">부동산사무소</span> {{ props.row.realtorName }}
                        </div>
                        <div>
                            <span class="fw-bold">대표</span> {{ props.row.representativeName }}
                            <span class="fw-bold">전화</span> {{ props.row.representativeTelNo }}
                            <span class="fw-bold">mobile</span> {{ props.row.cellPhoneNo }}
                        </div>
                    </template> -->
                         
                </vue-good-table>
        </div>

    </main>
</template>

<script>
// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';
//로딩바
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

import { Buffer } from 'buffer';

export default {
    components: {
        FadeLoader,
        VueGoodTable,
    },    
    //clipboard
    setup() {
      const message = 'Hello Clipborad!'
      const onCopy = (e) => {
        // alert('You just copied: ' + e.text)
        console.log(e)
      }
      const onError = (e) => {
        console.log(e)
        alert('Failed to copy texts')
      }

      return { message, onCopy, onError }
    },    
    computed: {
        user() {
            return this.$store.state.user;
        },
        selected() {
            let rowSelection=this.rowSelection
            let rowSelection1=[]
            for (let i = 0; i < rowSelection.length; i++) {
                // alert(i)
                rowSelection1.push(rowSelection[i].cellPhoneNo)
            }
            if (rowSelection1.length > 20) {
                this.$swal.fire(
                    {
                    icon: 'error',
                    title: '20개 초과되었습니다!!',
                    text: '먼저 선택된 20개 전화번호를 복사할려면 [선택한 전화번호 복사하기] 버튼을 클릭하거나 아니면 모두체크해제 후 다시 선택하시기 바랍니다.',
                    footer: '좋은 결과가 있기를 진심으로 바라겠습니다!!'
                    }
                ) 
                rowSelection1 = rowSelection1.slice(0,20)
            }
            return rowSelection1
        }
    },
    mounted() {   

    },
    created() {
        this.sido = "경상남도"
        this.sido = this.$route.params.sido;
        this.sigun = this.$route.params.sigun;
        
        this.getAgentList();
        this.getSidoList();
        this.getSigunList();
        this.getSigunList1();
        this.getDongList();
    },
    data() {
        return {
            isLoading: false,            
            AgentList: [],
            SidoList: [],
            SigunList: [],
            SigunList1: [],
            DongList: [],
            Sigun1: [],
            Sigun2: [],
            Sigun3: [],
            sido: "경상남도",
            sigun: "진주시",
            dong: "",

            columns: [
                {
                label: '소재지',
                field: 'address',
                thClass: 'text-center user-select-none',
                tdClass: 'text-start user-select-none p-2',
                },
                {
                label: '부동산사무소',
                field: 'realtorName',
                thClass: 'text-center user-select-none',
                tdClass: 'text-center user-select-none',
                },
                {
                label: '대표',
                field: 'representativeName',
                thClass: 'text-center user-select-none',
                tdClass: 'text-center user-select-none',
                },
                {
                label: '전화',
                field: 'representativeTelNo',
                thClass: 'text-center user-select-none',
                tdClass: 'text-center user-select-none',
                },
                {
                label: 'mobile',
                field: 'cellPhoneNo',
                thClass: 'text-center user-select-none',
                tdClass: 'text-center user-select-none',
                },                                                                
                // {
                // label: '시군구',
                // field: 'sigun',
                // thClass: 'text-center',
                // tdClass: 'text-center',                
                // // type: 'number',
                // },
                // {
                // label: '읍면동',
                // field: 'dong',
                // thClass: 'text-center',
                // tdClass: 'text-center',                
                // // type: 'date',
                // // dateInputFormat: 'yyyy-MM-dd',
                // // dateOutputFormat: 'MMM do yy',
                // },
                // {
                // label: '사무소',
                // field: 'realtorName',
                // thClass: 'text-center',
                // tdClass: 'text-center',                      
                // // type: 'number',
                // },
                // {
                // label: '소장님',
                // field: 'representativeName',
                // thClass: 'text-center',
                // tdClass: 'text-center',                
                // // type: 'number',
                // }, 
                // {
                // label: 'H.P',
                // field: 'cellPhoneNo',
                // thClass: 'text-center',
                // tdClass: 'text-center',                        
                // // type: 'number',
                // },                                        
            ],
            rowSelection: []


        };
    },
    methods: {
        async getAgentList() {
            this.isLoading = true
            let agentlist = await this.$api("/api/AgentList",'post', { param: [this.sido,this.sigun] });
            console.log(Buffer)
            console.log(agentlist[0].address.data)
            console.log((Buffer.from(agentlist[0].address.data)).toString('utf-8').replace(/'/g, '"'))
            let agentlist_resolve=[]
            for (let i = 0; i < agentlist.length; i++) {
                agentlist_resolve.push({
                    'address':(Buffer.from(agentlist[i].address.data)).toString('utf-8').replace(/'/g, '"'),
                    'realtorName':(Buffer.from(agentlist[i].realtorName.data)).toString('utf-8').replace(/'/g, '"'),
                    'representativeName':(Buffer.from(agentlist[i].representativeName.data)).toString('utf-8').replace(/'/g, '"'),
                    'representativeTelNo':(Buffer.from(agentlist[i].representativeTelNo.data)).toString('utf-8').replace(/'/g, '"'),
                    'cellPhoneNo':(Buffer.from(agentlist[i].cellPhoneNo.data)).toString('utf-8').replace(/'/g, '"')
                })
                // inguList.push(Number(data[i].ingu))

            }               
            this.AgentList = agentlist_resolve
            console.log(this.AgentList)
            this.isLoading = false

        },
        async getSidoList() {
            let SidoList = await this.$api("/api/SidoList",'post', {});
            this.SidoList = SidoList;

        },
        async getSigunList() {
            let SigunList = await this.$api("/api/SigunList",'post', {});

            this.SigunList = SigunList;
        },
        async getSigunList1() {
            let SigunList1 = await this.$api("/api/SigunList1",'post', { param: [this.sido] });

            this.SigunList1 = SigunList1;
        },
        async getDongList() {
            let DongList = await this.$api("/api/DongList",'post', {});
            this.DongList = DongList;
        },
        changeSido(a) {
            this.$router.push({ path: '/agentlist-good/' + a + '/' + this.sigun });
        },
        changeSigun(a) {
            console.log(a)
            this.$router.push({ path: '/agentlist-good/' + this.sido + '/' + a });
        },
        //vue-good-table선택된값가져오기
        selectionChanged(params) {
            this.rowSelection = params.selectedRows;
        },
        //div텍스트복사
        copyTextNoInput() {
        const storage = document.createElement('textarea');
        storage.value = this.$refs.message.innerHTML;
        this.$refs.reference.appendChild(storage);
        storage.select();
        storage.setSelectionRange(0, 99999);
        Document.execCommand('copy');
        this.$refs.reference.removeChild(storage);
        },
  
        TelNumCopy() {
            // this.$swal.fire({
            //     position: 'top-end',
            //     icon: 'success',
            //     title: this.selected,
            //     showConfirmButton: false,
            //     timer: 1500
            // });
            if (this.selected.length==0){
                this.$swal.fire(
                    {
                    icon: 'warning',
                    title: '선택된 전화번호가 없습니다.',
                    text: '아래 테이블에서 부동산사무소를 선택하세요.',
                    footer: '좋은 결과가 있기를 진심으로 바라겠습니다!!'
                    }
                )    
            } else {
                this.$swal.fire(
                    {
                    icon: 'success',
                    title: this.selected.length + '개 전화번호가 복사되었어요',
                    text: '전화번호가 복사되었습니다. PC버전의 카카오톡,네이버밴드 등 각종 SNS에 붙여넣기 하신후 여러분 각자 핸드폰으로 전송하셔서 사용하시기 바랍니다.',
                    footer: '좋은 결과가 있기를 진심으로 바라겠습니다!!'
                    }
                )    
            }
        
        }             
    }
}
</script>

<style scoped>
.loading {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
</style>