<template>

<div id="app">

  <div v-if="$isMobile()">
  
    <div class="mt-3">
      <div class="container-fluid">
    
        <div class="btn-toolbar mb-1" role="toolbar" aria-label="Toolbar with button groups" title="뉴스필터링">
          <div class="btn-group col-12" role="group" aria-label="First group">
            <!-- <a class="nav-link active" aria-current="page" href="#">{{a}}</a> -->
            <button type="button" class="btn btn-danger btn-sm me-1">{{news_ymd.replace(/(\d{4})(\d{2})(\d{2})/g,
            '$2/$3')}}({{getYoil(news_ymd)}})</button>
            <span v-for="(item, i) in DateList.slice(0,4)" :key=i>
              <button type="button" class="btn btn-succes btn-sm me-1"
                @click="news_ymd_change(item)">{{item.replace(/(\d{4})(\d{2})(\d{2})/g,
                '$2/$3')}}({{getYoil(item)}})</button>
            </span>
          </div>
        </div>
    
        <div id="my_card" class="card-deck scroll">
          <div class="card mb-3 text-dark bg-light" v-for="(a, i) in filters" :value="a['id']" :key=i>
            <div class="row g-0" @click="goPage(a.url,'뉴스상세보기',900,900)" style="cursor:pointer;">
              <!-- <div class="col-md-1 align-middle">
                <img :src="a.img_url" class="img-thumbnail mt-3" alt="...">
              </div> -->
              <div class="col-md-11">
                <div  style="font-size:0.8em" class="card-body">
                  <p style="font-size:larger" class="card-title text-start fw-bold">{{a.title}}</p>
                  <p class="card-text text-start">{{a.contents}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    
    </div>
  
  </div>

  <div v-else>
    <div class="mt-3">
      <div class="container-fluid">
    
        <div class="btn-toolbar mb-1" role="toolbar" aria-label="Toolbar with button groups" title="뉴스필터링">
          <div class="btn-group col-12" role="group" aria-label="First group">
            <!-- <a class="nav-link active" aria-current="page" href="#">{{a}}</a> -->
            <button type="button" class="btn btn-danger btn-sm me-1">{{news_ymd.replace(/(\d{4})(\d{2})(\d{2})/g,
            '$2월$3일')}}({{getYoil(news_ymd)}}) 부동산뉴스</button>
            <span v-for="(item, i) in DateList.slice(0,8)" :key=i>
              <button type="button" class="btn btn-succes btn-sm me-1"
                @click="news_ymd_change(item)">{{item.replace(/(\d{4})(\d{2})(\d{2})/g,
                '$2월$3일')}}({{getYoil(item)}})</button>
            </span>
          </div>
        </div>
    
        <div id="my_card" class="card-deck scroll">
          <div class="card mb-3 text-dark bg-light" v-for="(a, i) in filters" :value="a['id']" :key=i>
            <div class="row g-0" @click="goPage(a.url,'뉴스상세보기',900,900)" style="cursor:pointer;">
              <div class="col-md-1 align-middle">
                <img :src="a.img_url" class="img-thumbnail mt-3" alt="...">
              </div>
              <div class="col-md-11">
                <div class="card-body">
                  <h5 class="card-title text-start fw-bold">{{a.title}}</h5>
                  <p class="card-text text-start fs-6">{{a.contents}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    
    </div>
  </div>

</div>



</template>

<script>
// import $ from 'jquery';

export default {
    computed: {
      // user() {
      //     return this.$store.state.user;
      // },
      google_account() {
          return this.$store.state.google_account;
      },      
      filters() {
        // let ymdd=this.ymd
          return this.NewsList.filter(item => item.ymd === this.news_ymd)
      }        
    },
    mounted() {
      // //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
      // if (this.user.pro_user != 1) {
      //     this.showAlert_NoUser();
      //     this.$router.push({ path: '/' });
      // }      
    },
    // beforeRouteEnter (to,from,next) {
    //   console.log(to,from,next)
    //   next()
    // },
    created() {
        // this.sido = "경상남도"
        // this.sido = this.$route.params.sido;
        // this.ymd = this.$route.params.ymd;

        // this.a = this.DateList[-1];

        this.getNewsList();
        this.getNewsDateList();
        this.getSigunList();
    },
    data() {
        return {
            NewsList: [],
            NewsDateList:[],
            DateList:[],
            ymd: '20221007',
            news_ymd:'',
            SigunList:[]
        };
    },
    methods: {

      async getNewsList() {
          let NewsList = await this.$api("/api/NewsList",'post', { param: [this.ymd] });

          this.NewsList = NewsList;
      },
      async getNewsDateList() {
          let NewsDateList = await this.$api("/api/NewsDateList",'post', {});

          this.NewsDateList = NewsDateList;

          let DateList=[]
          for(var i in NewsDateList) {
            DateList.push(NewsDateList[i].ymd)
          }
          // console.log(DateList[DateList.length - 1])
          this.DateList = DateList.sort().reverse();
          this.news_ymd = this.DateList[0];
      },
      getYoil(y) {
        let Yoil=new Date(y.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'))
        const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
        return WEEKDAY[Yoil.getDay()]
      },
      goPage(urlLink,name,w,h) {
        window.open(urlLink,name,'scrollbars,width='+w+',height='+h);
      },

      showAlert() {
        this.$swal.fire({
            // position: 'bottom-end',
            icon: 'warning',
            title: '로그인이 필요합니다.',
            // showConfirmButton: false,
            // timer: 1500
        });
        // this.$Swal({
        //   position: 'top-end',
        //   icon: 'success',
        //   title: 'Your work has been saved',
        //   showConfirmButton: false,
        //   timer: 1500
        // });      
      },

      // showAlert_login() {
      //   this.$swal.fire({
      //     // position: 'bottom-end',
      //     icon: 'success',
      //     title: '로그인 되었습니다.',
      //     showConfirmButton: false,
      //     timer: 1500
      //   });    
      // },
      // showAlert_logout() {
      //   this.$swal.fire({
      //     // position: 'bottom-end',
      //     icon: 'success',
      //     title: '로그아웃 되었습니다.',
      //     showConfirmButton: false,
      //     timer: 1500
      //   });    
      // },    
      // showAlert_NoUser() {
      //   this.$swal.fire({
      //     // position: 'bottom-end',
      //     icon: 'success',
      //     title: '사용권한이 없습니다.',
      //     showConfirmButton: false,
      //     timer: 1500
      //   });    
      // },          
      news_ymd_change(b){
        this.news_ymd=b
      },
      async getSigunList() {
          let SigunList = await this.$axios("/get/r1_week/매매/지수/48240",'post', {});
          console.log(SigunList)
          this.SigunList = SigunList;
      },                               

    }
}
</script>
