<template>
  <div id="chart">
    <ApexLineColumn :Ymd="Ymd" :ColumnData="ColumnData" :LineData="LineData"></ApexLineColumn>
    
    <!-- {{ Ymd }}
    {{ ColumnData }}
    {{ LineData }} -->
  </div>
</template>

<script>
// import VueApexCharts from "vue3-apexcharts";
import ApexLineColumn from '../components/ApexLineColumn.vue'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    ApexLineColumn,
  },
  computed: {
    Ymd() {
      let R1WeekList=cloneDeep(this.R1WeekList)
      let Ymd=[]
      for (let i = 0; i < R1WeekList.length; i++) {
        Ymd.push(new Date(R1WeekList[i].ymd).getTime())
      }
      console.log(Ymd)
      return Ymd
    },
    ColumnData() {
      let R1WeekList=cloneDeep(this.R1WeekList)
      let ColumnData=[]
      for (let i = 0; i < R1WeekList.length; i++) {
        ColumnData.push(R1WeekList[i].m_dt)
      }
      // console.log(ColumnData)
      return ColumnData
    },
    LineData() {
      let R1WeekList=cloneDeep(this.R1WeekList)
      let LineData=[]
      for (let i = 0; i < R1WeekList.length; i++) {
        LineData.push(R1WeekList[i].m_js)
      }
      // console.log(LineData)
      return LineData
    }    
   
  },  
  mounted() {     
  },
  created() {
      this.getSidoList();
      this.getSigunList();
      this.getSigunList1();
      // this.getR1WeekList();
      // this.getR1Region();
      this.getR1WeekList();

  },  
  data() {
    return{
      R1WeekList: [],
    };
  },
  methods: {
    getavgMax() {
      let dataList = [];
      for (let i = 0; i < this.filters.length; i++) {
          dataList.push(Number(this.filters[i].avg))
      }
      this.avgMax = Math.max(...dataList)      
      // console.log(this.filters);
    },
    // async getR1Region() {
    //     let R1Region = await this.$api("/api/R1Region")
    //     this.R1Region = R1Region;  
    // },
    async getR1WeekList() {
      let aaa=11680
      let R1WeekList = await this.$api("/api/R1WeekList","post",{ param: aaa })
      this.R1WeekList=R1WeekList
    },
    async getR1WeekList1() {
      let selectedCake=this.selectedCake
      let R1WeekList1s=[]
      
      let series1=[]
      this.isLoading = true
      for (let i = 0; i < selectedCake.length; i++) {
        let R1WeekList1 = await  this.$axios(`/get/r1_month/매매/지수/${selectedCake[i]}`, {});
        let sido=R1WeekList1[0].sido
        let sigun=R1WeekList1[0].sigun
        let sidosigun=[]
        let data=[]

        // console.log(sido,sigun)
        sidosigun.push(sido+" "+sigun)
        for (let i = 0; i < R1WeekList1.length; i++) {
          let ymd_1=new Date(R1WeekList1[i].ymd)
          let ymd=new Date(ymd_1.setDate(ymd_1.getDate() + 1))
          let value=R1WeekList1[i].value.toFixed(2)            
          // categories1.push(ymd)
          data.push([ymd,value])                
        }
        series1.push({name:sidosigun[0],data:data})         
        R1WeekList1s.push(R1WeekList1)
      }
      
      this.series=series1
      this.isLoading = false
    },    
    // addSeries() {
    //   let selectedCake=this.selectedCake

    //   let series1=[]
    //   // for (let i = 0; i < selectedCake.length; i++) {
    //   let R1WeekList = this.$api("/api/R1WeekList",{ param: selectedCake[0] })
    //   //   let data1=[];
    //   //   let categories1=[];
    //   //   let sido=R1WeekList[0].sido
    //   //   let sigun=R1WeekList[0].sigun      
    //   //   for (let i = 0; i < R1WeekList.length; i++) {
    //   //     let ymd_1=new Date(R1WeekList[i].ymd)
    //   //     let ymd=new Date(ymd_1.setDate(ymd_1.getDate() + 1))
    //   //     let value=R1WeekList[i].value.toFixed(2)
    //   //     categories1.push(ymd)
    //   //     data1.push([ymd,value])
    //   //   }
        
    //   //   // console.log(data1)
    //   //   // console.log(categories1)

    //   //   // this.series[0].data = data1;
    //   //   // this.chartOptions.xaxis.categories = categories1;


    //   //   console.debug('Push new series');
    //   //   series1.push({
    //   //     name: sido+" "+sigun,
    //   //     data: data1,
    //   //   });
    //   // }
    //   console.log(R1WeekList)
    //   this.series=series1

    // },             
    async getSidoList() {
        let SidoList = await this.$api("/api/SidoList", {});
        this.SidoList = SidoList;
    },
    async getSigunList() {
        let SigunList = await this.$api("/api/SigunList", {});
        this.SigunList = SigunList;
    },
    async getSigunList1() {
        let SigunList1 = await this.$api("/api/SigunList1", { param: [this.sido] });
        this.SigunList1 = SigunList1;
    },
    changeSido() {
        // this.$router.push({ path: '/agt' });
        this.sido = '대구광역시';
        this.getUsers();
    },
    changeSigun() {
        // console.log(a)
        this.$router.push({ path: '/agt'});
    },

    sidoChange() {
      this.sigun='구미시';
    },

    changeMode() {
      this.modeBool = !this.modeBool;
      this.config.checkMode = this.modeBool ? 0 : 1;
    },

    getFormatDate(date) {
      const year = date.getFullYear();
      const month = (1 + date.getMonth());
      const day = date.getDate();
      return `${year}년 ${month}월 ${day}일 `; //Template literals 이용
    },
    doSort(field) {
        if(field == this.sort.field){
            this.sort.desc = !this.sort.desc
        }else{
            this.sort.field = field;
            this.sort.desc = true;
        }
    },
    heatMap(c_index_1,c_index) {
   
      if(c_index_1 > 0){
        if(c_index < 14) {
          return `position: absolute; top: 0px; left: 0px; right: 0px; z-index: 0; display: block; height: 100%; background-color: ${this.redList[c_index]}; color:black; text-align:left;`
        } else{
          return `position: absolute; top: 0px; left: 0px; right: 0px; z-index: 0; display: block; height: 100%; background-color: ${this.redList[c_index]}; color:white; text-align:left;`          
        }
      } else if(c_index_1 < 0) {
        if(c_index <= 8) {
          return `position: absolute; top: 0px; left: 0px; right: 0px; z-index: 0; display: block; height: 100%; background-color: ${this.blueList[c_index]}; color:black; text-align:left;`
        } else if(c_index > 8) {
          return `position: absolute; top: 0px; left: 0px; right: 0px; z-index: 0; display: block; height: 100%; background-color: ${this.blueList[c_index]}; color:white; text-align:left;`          
        }
      }
    },
    g5(c) {
      if(c === 1){
        return `badge bg-dark`
      } else if(c === 2) {
        return `badge bg-primary`
      } else if(c === 3) {
        return `badge bg-info text-dark`
      } else if(c === 4) {
        return `badge bg-secondary`
      } else if(c === 5) {
        return `badge bg-danger`
      }
    },
    toggleCheckbox() {
      this.checkbox = !this.checkbox
      if(this.checkbox==false) {
        this.pym2='평당가';
        // this.columns[6].hidden = false;
      } else {
        this.pym2='㎡당가';
        // this.columns[6].hidden = true;
      }
      // this.$emit('setCheckboxVal', this.checkbox)
    },
    toggleCheckbox1() {
      this.checkbox1 = !this.checkbox1
      if(this.checkbox1==false) {
        this.noise='한국부동산원';
        this.columns[3].hidden = true;
        this.columns[5].hidden = true;
        this.columns[8].hidden = true;
        this.styleClass ='vgt-table bordered fs-6';        
      } else {
        this.noise='KB부동산';
        this.columns[3].hidden = false;
        this.columns[5].hidden = false;
        this.columns[8].hidden = false;
        this.styleClass ='vgt-table bordered fs-6';        
      }

    },    

    updateData: function(timeline) {
      this.selection = timeline
  
      switch (timeline) {
        case 'one_month':
          this.$refs.chart.zoomX(
            new Date('28 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'six_months':
          this.$refs.chart.zoomX(
            new Date('27 Sep 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'one_year':
          this.$refs.chart.zoomX(
            new Date('27 Feb 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'ytd':
          this.$refs.chart.zoomX(
            new Date('01 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'all':
          this.$refs.chart.zoomX(
            new Date('23 Jan 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        default:
      }
    },
    // showAlert() {
    //   this.$swal.fire({
    //       icon: 'warning',
    //       title: '로그인이 필요합니다.',
    //   });
    // },
    // showAlert_login() {
    //   this.$swal.fire({
    //     // position: 'bottom-end',
    //     icon: 'success',
    //     title: '로그인 되었습니다.',
    //     showConfirmButton: false,
    //     timer: 1500
    //   });    
    // },
    // showAlert_logout() {
    //   this.$swal.fire({
    //     // position: 'bottom-end',
    //     icon: 'success',
    //     title: '로그아웃 되었습니다.',
    //     showConfirmButton: false,
    //     timer: 1500
    //   });    
    // },    
    // showAlert_NoUser() {
    //   this.$swal.fire({
    //     // position: 'bottom-end',
    //     icon: 'success',
    //     title: '사용권한이 없습니다.',
    //     showConfirmButton: false,
    //     timer: 1500
    //   });    
    // },        

  
  },
  
};

</script>

<style scoped>
td {
  padding: 4px;
}

.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
</style>