<template>
  <HorizontalBar :entries="entries" :columns="columns" :format="format" />
</template>


<script>
import HorizontalBar from '../components/HorizontalBar.vue'

export default {
  components: {
    HorizontalBar
  },
  computed: {
    user() {
        return this.$store.state.user;
    },
  },      
  mounted() {
    //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
    if (this.user != 1) {
        this.showAlert_NoUser();
        this.$router.push({ path: '/' });
    }       
  }, 
  data() {
    return{
      entries:[
      {name: "E", value: 0.12702},
      {name: "T", value: 0.09056},
      {name: "A", value: 0.08167},
      {name: "O", value: 0.07507},
      {name: "I", value: 0.06966},
      {name: "N", value: 0.06749},
      {name: "S", value: 0.06327},
      {name: "H", value: 0.06094},
      {name: "R", value: 0.05987},
      {name: "D", value: 0.04253},
      {name: "L", value: 0.04025},
      {name: "C", value: 0.02782},
      {name: "U", value: 0.02758},
      {name: "M", value: 0.02406},
      // {name: "W", value: 0.0236},
      // {name: "F", value: 0.02288},
      // {name: "G", value: 0.02015},
      // {name: "Y", value: 0.01974},
      // {name: "P", value: 0.01929},
      // {name: "B", value: 0.01492},
      // {name: "V", value: 0.00978},
      // {name: "K", value: 0.00772},
      // {name: "J", value: 0.00153},
      // {name: "X", value: 0.0015},
      // {name: "Q", value: 0.00095},
      // {name: "Z", value: 0.00074}
    ],
    columns: ["letter", "frequency"],
    format: "%",
    }
  },
  methods:{
    showAlert() {
      this.$swal.fire({
          icon: 'warning',
          title: '로그인이 필요합니다.',
      });
    },
    showAlert_login() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그인 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },
    showAlert_logout() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '로그아웃 되었습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },    
    showAlert_NoUser() {
      this.$swal.fire({
        // position: 'bottom-end',
        icon: 'success',
        title: '사용권한이 없습니다.',
        showConfirmButton: false,
        timer: 1500
      });    
    },        
  }  

}
</script>



<!-- <style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 60px auto;
  width: 1024px;
}
</style> -->