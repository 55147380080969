<template>
  <div>


    <!-- Modal -->
    <div class="modal fade" id="exampleModal_form" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel"><span class="badge text-bg-danger">안내</span> 다음<button
                class="btn btn-primary btn-sm ms-2" @click="handleSignInGoogle"><i class="bi bi-google"></i>
                회원등록하기</button>를 클릭하여 회원등록 후 본 설문지를 제출해주시기 바랍니다.</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdcYRgedsEmtKnKl_PLGrGOHf68c_7wpHs4JLd_ixFwK-Pppw/viewform?embedded=true"
              width="100%" height="1650" frameborder="0" marginheight="0" marginwidth="0">로드 중…</iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success float-end">
              <i class="bi bi-telephone-outbound-fill"></i> 010-9664-2399
            </button>
            <button type="button" class="btn btn-secondary text-light" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal부분 -->



    <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-gray-900 p-0">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/"><i class="bi bi-code-square"></i> 땅짚고PRO</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse pull-left collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">

            <li v-if="user" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                부동산동향
              </a>
              <ul class="dropdown-menu dropdown-menu-dark border border-light" aria-labelledby="navbarDropdown">
                <li><router-link class="dropdown-item ps-2" to="/news">부동산뉴스</router-link></li>
                <li>
                  <hr class="dropdown-divider border-light">
                </li>
                <li><router-link class="dropdown-item ps-2" to="/agt-good">아파트급지표</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="/updown">주간등락조회</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="/fastsell/서울시/강남구">네이버급매</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="/agentlist-good/서울시/강남구">소장님114</router-link></li>
                <!-- <li><router-link class="dropdown-item ps-2" to="/agentlist/서울시/강남구">소장님114(구)</router-link></li> -->
              </ul>
            </li>

            <li v-if="user" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Top-Dwon
              </a>
              <ul class="dropdown-menu dropdown-menu-dark border border-light" aria-labelledby="navbarDropdown">
                <li class="dropdown-item ps-2 bg-primary">지역분석</li>
                <li><router-link class="dropdown-item ps-2" to="/krheatmap">전국 아파트 증감지도</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="/seriescompare">지역별 시계열비교</router-link></li> 
                <!-- <li><router-link class="dropdown-item ps-2" to="/ChartSeries">시계열 콤보차트</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="/seriescombo">시계열 콤보차트</router-link></li> -->
                  <li><router-link class="dropdown-item ps-2" to="/seriescombo1">아파트시계열 콤보차트</router-link></li>                           
                <!-- <li><router-link class="dropdown-item ps-2" to="/krheatmapm">전국 아파트 monthly 증감지도</router-link></li> -->
                <li><router-link class="dropdown-item ps-2" to="#">지역별 아파트펀더멘털(예정)</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="#">지역별 투자자동향(예정)</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="#">지역별 법인매수동향(예정)</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="#">네이버급매PRO(예정)</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="#">지역별 매물동향(예정)</router-link></li>


                <li>
                  <hr class="dropdown-divider border-light">
                </li>
                <li class="dropdown-item ps-2 bg-primary">아파트분석</li>
                <li><router-link class="dropdown-item ps-2" to="#">땅짚고 투자테이블(예정)</router-link></li>
                <!-- <li><router-link class="dropdown-item ps-2" to="/ChartSeries">시계열차트</router-link></li> -->
                <!-- <li><router-link class="dropdown-item ps-2" to="#">아파트급지표</router-link></li>
              <li><router-link class="dropdown-item ps-2" to="#">주간등락조회</router-link></li>
              <li><router-link class="dropdown-item ps-2" to="#">네이버급매</router-link></li>
              <li><router-link class="dropdown-item ps-2" to="#">증감지도</router-link></li> -->

              </ul>
            </li>

            <li v-if="user" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Bottom-up
              </a>
              <ul class="dropdown-menu dropdown-menu-dark border border-light" aria-labelledby="navbarDropdown">
                <li><router-link class="dropdown-item ps-2" to="#">전국 상승률TOP100(예정)</router-link></li>
                <li><router-link class="dropdown-item ps-2" to="#">전국 하락률TOP100(예정)</router-link></li>
                <li>
                  <hr class="dropdown-divider border-light">
                </li>
                <li><router-link class="dropdown-item ps-2" to="#">전국 거래량TOP(예정)</router-link></li>
              </ul>
            </li>

            <!-- <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/news">부동산뉴스</router-link>
          </li>         
          
          <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/agt-good">아파트급지표</router-link>
          </li>

          <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/updown">주간등락단지조회</router-link>
          </li> -->

            <!-- <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/chartseries">시계열분석</router-link>
          </li>

          <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/useform">땅짚고이용신청</router-link>
          </li> -->

            <!-- <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/test">test</router-link>
          </li> -->

            <!-- <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/d3chart">d3chart</router-link>
          </li> -->

            <!-- <li v-if="pro_user==1" class="nav-item">
            <router-link class="nav-link" to="/fastsell/서울시/강남구">급매잡기</router-link>
          </li>    -->



          </ul>


          <div class="d-flex">
            <!-- <div v-if="pro_user==1.email==undefined" >
            <img src="./assets/kakao_login.png" class="rounded" @click="kakaoLogin();" alt="카카오로그인" style="cursor:pointer;" />
          </div>
          <div v-else>
            <button type="button" class="btn btn-secondary btn-sm"><i class="bi bi-person-square"></i>
            {{pro_user==1.profile.nickname}} <i class="bi bi-gear"></i></button>
            <button class="btn btn-danger btn-sm ms-2" type="button" @click="kakaoLogout();">로그아웃 <i class="bi bi-box-arrow-right"></i></button>
          </div> -->

            <div id="GoogleSignIn" v-if="pro_user == 0">
              <button class="btn btn-primary btn-sm ms-2" @click="handleSignInGoogle();"><i class="bi bi-google"></i>
                로그인</button>
              <button data-bs-toggle="modal" data-bs-target="#exampleModal_form" class="btn btn-warning btn-sm ms-2"><i
                  class="bi bi-ui-checks-grid"></i> 사용신청</button>
              <!-- <img src="./assets/google_sm.png" class="rounded" @click="handleSignInGoogle" alt="구글로그인" style="cursor:pointer;" /> -->
            </div>

            <!-- <button type="button" class="btn btn-secondary btn-sm"><i class="bi bi-person-square"></i>
            {{ result.user1.displayName }} <i class="bi bi-gear"></i></button> -->

            <!-- &&pro_user==1 -->
            <div id="logout" v-else-if="user">
              <button type="button" class="btn btn-secondary btn-sm"><i class="bi bi-person-square"></i>
                {{ this.Getnickname() }} <i class="bi bi-gear"></i></button>
              <button class="btn btn-danger btn-sm ms-2" @click="handlesignOut">로그아웃</button>
              <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal_form" class="btn btn-primary btn-sm ms-2"><i
                class="bi bi-google"></i> 이용신청</button> -->
            </div>
            <div id="GoogleSignIn" v-else>
              <button class="btn btn-primary btn-sm ms-2" @click="handleSignInGoogle"><i class="bi bi-google"></i>
                로그인</button>
              <button data-bs-toggle="modal" data-bs-target="#exampleModal_form"
                class="btn btn-warning btn-success btn-sm ms-2"><i class="bi bi-ui-checks-grid"></i> 사용신청</button>
            </div>


          </div>

        </div>
      </div>
    </nav>
    <!-- <button class="btn btn-primary btn-sm ms-2" @click="SignUpInGoogle"><i class="bi bi-google"></i>회원등록하기</button> -->
    <!-- {{ decode(token) }}
    쿠키겟???{{ $cookies.get('token') }}
    token???{{ token }}
    user의token??? {{ user.token }}

    {{ decode($cookies.get('token')).exp*1000 }}
    {{ Date.now() }}
      {{ user.login_yn }} -->
    <!-- 쿠키겟???{{ $cookies.get('keyin') }} -->
     <!-- cookie? {{ $cookies.get('account') }} -->
     <!-- {{ this.getnickname() }} -->
     <!-- {{ this.Getnickname() }} -->
    <router-view :key="$route.path" />

<!-- {{ this.$cookies.get('account').nickname }} -->

 

    <!-- <footer class="py-5 bg-light">
  </footer>  -->
  </div>
</template>

<script>
// // import HelloWorld from './components/HelloWorld.vue';
// import firebaseConfig from './firebaseConfig';
// import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";
// const provider = new GoogleAuthProvider();
// const auth = getAuth();
// firebaseConfig
// import axios from 'axios';

// import jwt_decode from "jwt-decode";
// // import HomeView from './views/HomeView.vue';
// // import HomeView from './views/HomeView.vue';

export default {
    name: "App",
    // components: {
    //   HelloWorld
    // },
    computed: {
        user() {
            // window.localStorage.removeItem('vuex'); //storage>local storage 삭제하는 코드(유료사용자조작할 수 있으므로)
            return this.$store.state.user;
        },
        cookie(){
          return document.cookie;
        }
    },
    mounted() {
        // window.localStorage.removeItem('vuex');
        if (!this.$cookies.get("account")) {
            // this.showAlert_logout();
            this.Logout();
        }
        // window.addEventListener('beforeunload', this.leave)
    },
    beforeUnmount() {
      // window.removeEventListener('beforeunload', this.leave)
    },    
    created() {
        // console.log("create");
        this.getNewsDateList();
        // this.login_yn();
        // this.getUserAcount();
        // window.localStorage.removeItem('vuex');
    },
    data() {
        return {
            result: "",
            user1: "",
            email: "abcdefg",
            UserAcount: "",
            google_account: "",
            pro_user: 2,
            isSignedIn: false,
            NewsDateList: [],
            ymd: "",
            token: ""
        };
    },  
    // methods: {
    //     async login_yn(이메일) {
    //       let 로그인여부 = await this.$api("/api/login_yn",'post', { param: 이메일 });
    //       this.UserAcount = 로그인여부
    //       // this.pro_user = this.UserAcount.pro_user
    //       // this.$store.commit("user", UserAcount[0])
    //     },
    //     //카카오로그인시스템
    //     // kakaoLogin() {
    //     //   window.Kakao.Auth.login({
    //     //     scope: 'profile_nickname	, account_email, gender, age_range',
    //     //     success: this.getProfile
    //     //   });
    //     // },
    //     // async login(kakao_account) {
    //     //   await this.$api('/api/login', {
    //     //     param: [
    //     //       { email: kakao_account.email, nickname: kakao_account.profile.nickname, gender: kakao_account.gender, age_range: kakao_account.age_range },
    //     //       { nickname: kakao_account.profile.nickname, gender: kakao_account.gender, age_range: kakao_account.age_range }
    //     //       ]
    //     //   });
    //     //   this.$store.commit("user", kakao_account)
    //     // },
    //     // kakaoLogout() {
    //     //   window.Kakao.Auth.logout((response) => {
    //     //     console.log(response);
    //     //     this.$store.commit("user", {});
    //     //     // alert("로그아웃 성공!");
    //     //     this.showAlert('로그아웃');
    //     //     this.$router.push({ path: '/' });
    //     //   });
    //     // },
    //     // getProfile(authObj) {
    //     //   console.log(authObj);
    //     //   window.Kakao.API.request({
    //     //     url: '/v2/user/me',
    //     //     success: res => {
    //     //       const kakao_account = res.kakao_account;
    //     //       console.log(kakao_account);
    //     //       this.login(kakao_account);
    //     //       // alert("로그인 성공!");
    //     //       // this.showAlert(user.profile.nickname);
    //     //       this.showAlert('로그인');
    //     //     }
    //     //   });
    //     // },    
    //     //firebase-회원등록
    //     SignUpInGoogle() {
    //         signInWithPopup(auth, provider)
    //             .then((result) => {
    //             const google_account = result.user;
    //             //파이어베이스구글로그인되면해당땅짚고에도등록시키기!!
    //             this.signup(google_account);
    //             //회원등록후이용신청유도
    //             this.showAlert_signup_complete();
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    //     },
    //     //땅짚고로그인
    //     handleSignInGoogle() {
    //         signInWithPopup(auth, provider)
    //             .then((result) => {
    //             const google_account = result.user;
    //             this.google_account = google_account;
    //             this.Login(result.user.email);
    //             this.email = result.user.email;
    //             this.isSignedIn = true;
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    //     },
    //     handlesignOut() {
    //         signOut(auth).then(() => {
    //             this.pro_user = 2;
    //             this.isSignedIn = false;
    //             // this.$store.commit("user", {});
    //             this.showAlert_logout();
    //             this.Logout();
    //             // this.$router.push({ path: "/" });
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    //     },
    //     //땅짚고등록
    //     async signup(google_account) {
    //         await axios.post("/signup", {
    //             param: [
    //                 { email: google_account.email, nickname: google_account.displayName, createdAt: new Date(parseInt(google_account.metadata.createdAt) + 9 * 60 * 60 * 1000), lastLoginAt: new Date(parseInt(google_account.metadata.lastLoginAt) + 9 * 60 * 60 * 1000) },
    //                 { nickname: google_account.displayName, createdAt: new Date(parseInt(google_account.metadata.createdAt) + 9 * 60 * 60 * 1000), lastLoginAt: new Date(parseInt(google_account.metadata.lastLoginAt) + 9 * 60 * 60 * 1000) }
    //             ]
    //         });
    //         let google_account1 = new Object();
    //         google_account1["pro_user"] = this.pro_user;
    //         google_account1["email"] = google_account.email;
    //         google_account1["nickname"] = google_account.displayName;
    //         google_account1["accessToken"] = google_account.accessToken;
    //         google_account1["metadata"] = google_account.metadata;
    //         // google_account1['pro_user']=1000000000;
    //         // google_account1['pro_user']=1000000000;
    //         // google_account1=google_account
    //         console.log(google_account);
    //         // this.$store.commit("user", google_account1)
    //     },
    //     //로그인+쿠키
    //     // async Login(email) {
    //     //     await axios
    //     //         .post("/login/account", { loginEmail: email })
    //     //         .then((res) => {
    //     //         this.pro_user = res.data.pro_user;
    //     //         this.token = res.data.token;
    //     //         this.$store.commit("user", res.data);
    //     //         if (res.data.pro_user == 1) {
    //     //           if (res.data.login_yn == 'logout') {
    //     //             this.showAlert_login();
    //     //           } else if (res.data.login_yn == 'login') {
    //     //             this.showAlert_MultiLogin();
    //     //             axios.delete("/login/account").then(() => {
    //     //                 this.token = "";
    //     //                 this.$store.commit("user", {});
    //     //                 // alert("로그아웃하였습니다.");
    //     //             });                          
    //     //           }
    //     //         }
    //     //         else if (res.data.pro_user == 0) {
    //     //           this.showAlert_NoUser();
    //     //           this.Logout();
    //     //         }
    //     //         // alert("로그인에 성공했습니다."+this.pro_user+this.token);
    //     //         console.log("로그인???", res.data);
    //     //     })
    //     //         .catch(() => {
    //     //         alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
    //     //     });
    //     // },
    //     async Login(email) {
    //         await axios
    //             .post("/login/account", { loginEmail: email })
    //             .then((res) => {
    //             console.log('클라이언트res:',res)
    //             if (res.data=="중복로그인") {
    //               //중복로그인 일 때
    //               this.showAlert_MultiLogin();
    //               this.Logout();

    //             } else {
    //               //중복로그인이 아닐 때
    //               this.pro_user = res.data.pro_user;
    //               // this.token = res.data.token;
    //               if (res.data.pro_user == 1) {
    //                   this.$store.commit("user", document.cookie)
                      
    //                   this.showAlert_login();
    //                   // alert("로그인에 성공했습니다." + this.pro_user + this.$cookies.get("keyin"));
    //               }
    //               else {
    //                   this.showAlert_NoUser();
    //                   // alert("사용권한이 없습니다!!!!!!")
    //                   this.Logout();
    //               }                  
    //             }

    //         })
    //             .catch(() => {
    //             //회원등록이 안되어 있을 때 에러가 난다. 따라서 회원등록 권유!!
    //             this.showAlert_SignUp();
    //             // alert("회원등록후 이용해 주시기 바랍니다.");
    //         });
    //     },
    //     async Logout() {
    //         await axios.get("/logout/account", {});
    //         this.$store.commit("user", null);
    //         this.$router.push({ path: "/" });

    //         // await this.$api("/api/login", "post", {
    //         //     param: [
    //         //         { email: this.user.email, login_yn:'logout' },
    //         //         { email: this.user.email, login_yn:'logout' }
    //         //     ]
    //         // });              
    //         // await axios.delete("/login/account").then(() => {
    //         //     this.token = "";
    //         //     this.$store.commit("user", {});
    //         //     // alert("로그아웃하였습니다.");
    //         // });        
    //     },
    //     //로그인+쿠키              
    //     decode(xxx) {
    //         if (xxx) {
    //             let aaa = jwt_decode(xxx);
    //             return aaa;
    //         }
    //         else {
    //             return { pro_user: 2 };
    //         }
    //     },
    //     showAlert_login() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "로그인 되었습니다.",
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     },
    //     showAlert_logout() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "로그아웃 되었습니다.",
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     },
    //     showAlert_SignUp() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "회원등록 후 이용해 주세요!!",
    //             // text: ' 본 땅짚고PRO 사용을 위해서 아래 회원등록을 해주세요.',                
    //             showConfirmButton: true,
    //             // confirmButtonColor: '#3085d6',                
    //             // showCancelButton: true,
    //             // confirmButtonText: '<button class="btn btn-primary btn-sm ms-2" @click="SignUpInGoogle"><i class="bi bi-google"></i>회원등록하기</button>',
    //             // showDenyButton: true,
    //             // denyButtonText: `확인`,
    //             // footer: '<button class="btn btn-primary btn-sm ms-2" onclick="SignUpInGoogle()"><i class="bi bi-google"></i>회원등록하기</button>'             
    //             // timer: 1500
    //         });
    //     },
    //     showAlert_NoUser() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "이용신청 후 이용해 주세요!",
    //             text: "아래 이용신청 후 사용해주시기 바랍니다.",
    //             showConfirmButton: true,
    //             confirmButtonColor: "#3085d6",
    //             // showCancelButton: true,
    //             confirmButtonText: "<span data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal_form\" ><i class=\"bi bi-ui-checks-grid\"></i> 이용신청</span>",
    //             showDenyButton: true,
    //             denyButtonText: `확인`,
    //         });
    //     },
    //     showAlert_signup_complete() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "회원가입이 완료되었습니다.",
    //             text: "아래 이용신청 후 사용해주시기 바랍니다.",
    //             showConfirmButton: true,
    //             confirmButtonColor: "#3085d6",
    //             // showCancelButton: true,
    //             confirmButtonText: "<span data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal_form\" ><i class=\"bi bi-ui-checks-grid\"></i> 이용신청</span>",
    //             showDenyButton: true,
    //             denyButtonText: `확인`,
    //             // timer: 1500
    //         });
    //     },
    //     showAlert_MultiLogin() {
    //         this.$swal.fire({
    //             // position: 'bottom-end',
    //             icon: "success",
    //             title: "중복로그인 감지되었습니다!!",
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //     },
    //     async getNewsDateList() {
    //         let NewsDateList = await this.$api("/api/NewsDateList", {});
    //         this.NewsDateList = NewsDateList;
    //         let DateList = [];
    //         for (var i in NewsDateList) {
    //             DateList.push(NewsDateList[i].ymd);
    //         }
    //         console.log(DateList[DateList.length - 1]);
    //         this.ymd = DateList[DateList.length - 1];
    //         this.DateList = DateList.sort().reverse();
    //     },
    // },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500&display=swap');

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2d2e2e;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* nav a.router-link-exact-active {
  color: #42b983;
} */

body {
  /* overflow-y: hidden; Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  font-size: 0.9em;
}
</style>