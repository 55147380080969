<template>

  <div id="app">
        <iframe
          src="../nvd3.html"
          width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0" scrolling="no">로드 중…</iframe>
  </div>


</template>

<script>
// import VueApexCharts from "vue3-apexcharts";
// // import the component
// import Treeselect from 'vue3-treeselect'
// // import the styles
// import 'vue3-treeselect/dist/vue3-treeselect.css'

// import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

export default {
  components: {
    // FadeLoader,
    // Treeselect,
    // apexchart: VueApexCharts,
  },
  computed: {
   
  },  
  mounted() {      
  },
  created() {
  },  
  data() {
    return{

    };
  },
  methods: {

  },
  
};

</script>

<style scoped>

</style>