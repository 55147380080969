import {createStore} from 'vuex'

//새로고침로그인유지
import persistedstate from 'vuex-persistedstate';
// import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    state() {
        return {
            user: {}
        }
    },
    mutations: {
        user(state, data) {
            state.user = data;
        }
    },
    //새로고침로그인유지
    plugins: [
        persistedstate({
        paths: ['user']
        })
    ]    
});

export default store;