<template>
    <div>
        <!-- <div class="row mb-2">
            <div class="col-12">
                <div class="row">

                    <div class="col-auto">
                        <select class="form-select" v-model="firm" @change="changeFirm(firm);">
                            <option value="kb_week">KB부동산</option>
                            <option value="r1_week">한국동산원</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <select class="form-select" v-model="trade" @change="changeTrade(trade);">
                            <option value="매매">매매</option>
                            <option value="전세">전세</option>
                        </select>
                    </div>
                    <div class="col-auto">
                        <select class="form-select" v-model="date" @change="changeDate(date);">
                            <option value="2022-12-05">2022-12-05</option>
                            <option value="2022-09-19">2022-09-19</option>
                            <option value="2022-09-12">2022-09-12</option>
                            <option value="2022-09-05">2022-09-05</option>
                            <option value="2022-08-29">2022-08-29</option>
                            <option value="2022-08-22">2022-08-22</option>
                            <option value="2022-08-15">2022-08-15</option>
                            <option value="2022-08-08">2022-08-08</option>
                        </select>
                    </div>                    
                </div>
            </div>
        </div> -->
        <!-- {{ KrheatmapList }} -->
        <table class="scaled">
            <tbody>
                <!-- <tr>
                    <td class="border-0" colspan="15" style="text-align: left; color: black;">
                        <h4><b><span v-if="this.provider=='KB부동산'" style="font-size: larger;color:#FF8000">{{ provider
                        }}</span><span v-else-if="this.provider=='한국부동산원'"
                                    style="font-size: larger;color:#0080FF">{{ provider }}</span>시계열<span
                                    style="font-size: larger;color:red">{{year}}년{{mon+1}}월{{ju}}주</span>기준<span
                                    v-if="this.ma_jun=='매매'"
                                    style="font-size: larger;color:#58D3F7">{{ma_jun}}증감</span><span
                                    v-else-if="this.ma_jun=='전세'"
                                    style="font-size: larger;color:#F78181">{{ma_jun}}증감</span>지도</b></h4>
                    </td>
                </tr> -->
                <tr v-for="(y, i) in YList" :key="i">
                    <td class="border-0" width=10></td>
                    <td width=52 height=38
                        :style="'table-layout:fixed;background-color:' + getColor(getQuartile(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']))"
                        :class="[KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['class'], KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['left'], KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['top'], KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['right'], KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['bottom']]"
                        v-for="(x, i) in XList" :key="i">
                        <!-- {{ KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['sigun_code'] }} -->
                        <!-- {{ getColor(getQuartile(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data'])) }} -->
                        <div v-if="KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']==null">
                            {{KrheatmapList[x['x'] + 14*(y['y']-1)-1]['sigun2'] }}
                        </div>

                        <div v-else>
                            <div v-if="getQuartile(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']) < -10 || getQuartile(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']) > 10"
                                style="color:white">
                                {{KrheatmapList[x['x'] + 14*(y['y']-1)-1]['sigun2'] }}
                                <div v-if="KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['class']!='border-0'">
                                    {{Number(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']).toFixed(2) }}
                                </div>
                            </div>
                            <div v-else>
                                {{KrheatmapList[x['x'] + 14*(y['y']-1)-1]['sigun2'] }}
                                <div v-if="KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['class']!='border-0'">
                                    {{Number(KrheatmapList[x['x'] + 14 * (y['y'] - 1) - 1]['data']).toFixed(2) }}
                                </div>
                            </div>
                        </div>

                    </td>
                    <td class="border-0" width=10></td>
                </tr>
                <tr>
                    <td class="border-0" colspan="14" style="text-align: right;">
                        <h5><b>부동산빅데이터<span style="font-size: larger;color:red">땅짚고PRO</span></b></h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>


<script>


export default {
    props:[
        'KrheatmapList',
        'KrheatmapList_all',
        'KrheatmapList_m',
        'KrheatmapList_j',


        'XList',
        'YList'
    ],

    mounted() {
        // //computed에서user()로 pro사용자여부? computed의user()는 App.js의 async getUserAcount에서 선언함, 그리고 store,vuex로 상태관리함 + 아래 showAlert_NoUser()있어야함.
        // if (this.user != 1) {
        //     this.showAlert_NoUser();
        //     this.$router.push({ path: '/' });
        // }       
    },
    created() {
        // console.log(this.$route.params.ymd)
        // console.log(this.$route.params.ma_jun)
        // this.ju = this.getWeekNumber(new Date(this.$route.params.ymd))
        // this.mon = new Date(this.$route.params.ymd).getMonth()
        // this.year = new Date(this.$route.params.ymd).getFullYear()
        this.ma_jun = this.$route.params.ma_jun

        this.firm = this.$route.params.provider;
        this.trade = this.$route.params.ma_jun;
        this.date = this.$route.params.ymd;

        if (this.$route.params.provider == 'kb_week') {
            this.provider = 'KB부동산'
        } else if (this.$route.params.provider == 'r1_week') {
            this.provider = '한국부동산원'
        }
        



        // this.getKrheatmapList();
        // this.getXList();
        // this.getYList();      
    },
    data() {
        return {
            // KrheatmapList: [],
            // XList: [],
            // YList: [],
            dataList: [],
            // blueList: ['#f2f7ff', '#e6f0ff', '#d9e8ff', '#cce0ff', '#bfd9ff', '#b2d1ff', '#a6c9ff', '#99c2ff', '#8cbaff', '#80b2ff', '#73abff', '#66a3ff', '#599cff', '#4d94ff', '#408cff', '#3385ff', '#267dff', '#1975ff', '#0d6eff', '#0066ff'],

            // blueList: ['#f2f2ff', '#e6e6ff', '#d9d9ff', '#ccccff', '#bfbfff', '#b2b2ff', '#a6a6ff', '#9999ff', '#8c8cff', '#8080ff', '#7373ff', '#6666ff', '#5959ff', '#4d4dff', '#4040ff', '#3333ff', '#2626ff', '#1919ff', '#0d0dff', '#0000ff'],
            // redList: ['#fff2f2', '#ffe6e6', '#ffd9d9', '#ffcccc', '#ffbfbf', '#ffb2b2', '#ffa6a6', '#ff9999', '#ff8c8c', '#ff8080', '#ff7373', '#ff6666', '#ff5959', '#ff4d4d', '#ff4040', '#ff3333', '#ff2626', '#ff1919', '#ff0d0d', '#ff0000'],

            blueList: ['#f2f2ff', '#e6e6ff', '#d9d9ff', '#ccccff', '#bfbfff', '#b2b2ff', '#a6a6ff', '#9999ff', '#8c8cff', '#8080ff', '#7373ff', '#6666ff', '#5959ff', '#4d4dff', '#4040ff', '#0000cc', '#0000b3', '#000099', '#000080', '#000066'],
            redList: ['#fff2f2', '#ffe6e6', '#ffd9d9', '#ffcccc', '#ffbfbf', '#ffb2b2', '#ffa6a6', '#ff9999', '#ff8c8c', '#ff8080', '#ff7373', '#ff6666', '#ff5959', '#ff4d4d', '#ff4040', '#cc0000', '#b30000', '#990000', '#800000', '#660000'],

            // redList: ['#fcf2f2', '#fae6e6', '#f7d9d9', '#f5cccc', '#f2bfbf', '#f0b2b2', '#eda6a6', '#eb9999', '#e88c8c', '#e68080', '#e37373', '#e06666', '#de5959', '#db4d4d', '#d94040', '#d63333', '#d42626', '#d11919', '#cf0d0d', '#cc0000'],
            provider:"",
            ma_jun: "매매",
            ymd: "2022-08-15",
            ji_code: "",
            ju: "",
            mon: "",
            year: "",
            firm: "r1_week",
            trade: "매매",
            date: "2022-08-15",

                        
        };
    },
    methods: {
        // async getKrheatmapList() {
        //     this.KrheatmapList = await this.$api("/api/KrheatmapList","post", { param: [this.$route.params.provider,this.$route.params.ma_jun,this.$route.params.ymd]});
        //     // console.log(this.getQuartile(-0.058914))
        // },
        getQuartile(n) {
            //console.log(this.KrheatmapList[0].x)
            let dataList = [];
            let dataListAbs = [];

            for (let i = 0; i < this.KrheatmapList_all.length; i++) {
                dataList.push(Number(this.KrheatmapList_all[i]))
                dataListAbs.push(Math.abs(Number(this.KrheatmapList_all[i])))
            }
            let max = Math.max(...dataListAbs)
            // let max2 = Math.max(...dataList)
            // console.log(max)
            // console.log(max2)
            if(n>0) {
                let quartile = Math.ceil(n * (20 / max))
                return quartile
            } else if (n<0) {
                let quartile = Math.floor(n * (20 / max))
                return quartile                
            } else if (n==0) {
                return 'white'
            }

        },
        getColor(n) {
            if (n > 0) {
                if (n>19){
                    return '#b71c1c'
                } else {
                    return this.redList[n - 1]
                }
            } else if (n < 0) {
                if (n < -19) {
                    return '#1a237e'
                } else {
                    return this.blueList[Math.abs(n) - 1]
                }
            } else {
                return 'white'
            }
        },
        // async getXList() {
        //     this.XList = await this.$api("/api/XList","post", {});
        // }, 
        // async getYList() {
        //     this.YList = await this.$api("/api/YList","post", {});
        // },
        goToPage() {
            this.$router.push({ path: '/krheatmap/' + this.$route.params.ymd });
        },
        //해당월 월요일만 가져오기 그후 계산해보기
        getWeekNumber(dateFrom = new Date()) {
            // 해당 날짜 (일)
            const currentDate = dateFrom.getDate();

            // 이번 달 1일로 지정
            const startOfMonth = new Date(dateFrom.setDate(1));

            // 이번 달 1일이 무슨 요일인지 확인
            const weekDay = startOfMonth.getDay(); // 0: Sun ~ 6: Sat

            //매달 시작일에 따른 빼줘야 하는 값
            const week_minus = new Array( 1, 0, 5, 4, 3, 2, 1 );

            // ((요일 - 1) + 해당 날짜) / 7일로 나누기 = N 주차
            return parseInt(((weekDay - week_minus[weekDay]) + currentDate) / 7) + 1;
        },
        changeFirm(a) {
            this.$router.push({ path: '/krheatmap/' + a + '/' + this.trade  + '/' + this.date});
        },
        changeTrade(a) {
            console.log(a)
            this.$router.push({ path: '/krheatmap/' + this.firm + '/' + a  + '/' + this.date});
        },       
        changeDate(a) {
            console.log(a)
            this.$router.push({ path: '/krheatmap/' + this.firm + '/' + this.trade  + '/' + a});
        },                                                    

    }
}
</script>

<style scoped>
table,th {
    border: 0px solid black;
    border-collapse: collapse;
    /* font-size: 11px; */
    font-weight: bold;
    text-align: center;
    padding: 0px;
}

/* tr {
    height: 25px;
} */

td {
    cursor: pointer;
    border-color: black;
    border-width: 1px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 12.5px;
    line-height: 120%;
}

.border-0 {
    border-width: 0px;
}

.t {
    border-color: black;
    border-top-width: 4px;
}

.b {
    border-color: black;
    border-bottom-width: 4px;
}

.l {
    border-color: black;
    border-left-width: 4px;
}

.r {
    border-color: black;
    border-right-width: 4px;
}

.a {
    min-width: 50px;
    border-color: black;
    border-width: 4px;
}
.scaled {
    transform:  scale(1);
    transform-origin: 100% 100%;
}
</style>