<template>
    <div id="chart">
      <apexchart height="800" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  props:[
      'Ymd',
      'LineData',
      'ColumnData',
  ],    
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    series1() {
      return this.series
    }
  },  
  mounted() {
      
  },
  created() {

  },  
  data() {
    return{
 
      series: [{
        name: '매매증감',
        type: 'column',
        data: this.ColumnData
      }, {
        name: '매매지수',
        type: 'line',
        data: this.LineData
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 3]
        },
        title: {
          text: '매매가격 콤보차트'
        },
        dataLabels: {
          // enabled: true,
          // enabledOnSeries: [1]
        },
        // labels: this.Ymd,
        xaxis: {
          type: 'datetime',
          categories:this.Ymd,
        },
        yaxis: [{
          title: {
            text: '매매증감',
          },
        
        }, {
          opposite: true,
          title: {
            text: '매매지수'
          },
          min: 60
        }]
      },
    };
  },
  methods: {
 
  },
  
};

</script>

<style scoped>

.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
</style>